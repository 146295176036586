import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { Answers, ComprehensivePricing, LineOfBusinessName, TotalPremiums } from '@breathelife/types';

import { useDispatch } from '../../Hooks';
import { QueryId } from '../../ReactQuery/common/common.types';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { getProductsWidgetTotalPremiums, getComprehensivePricing } from '../../Services/AssistedApplicationsService';

export function useGetProductsWidgetTotalPremiumsQuery(
  applicationId?: string,
  options?: UseQueryOptions<TotalPremiums | null>,
): UseQueryResult<TotalPremiums | null> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useQuery<TotalPremiums | null>(
    [QueryId.productsWidgetTotalPremiums, applicationId],
    async () => {
      if (!applicationId) {
        return Promise.reject(new Error('Application id is missing.'));
      }
      return getProductsWidgetTotalPremiums(applicationId);
    },
    {
      ...options,
      enabled: !!applicationId,
      onError: () => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.getProductsWidgetTotalPremiumsFailure'),
          }),
        );
      },
    },
  );
}

export function useGetComprehensivePricingQuery(
  applicationId?: string,
  answers?: Answers,
  answersV2?: Answers,
  lineOfBusinessName?: LineOfBusinessName,
  options?: UseQueryOptions<ComprehensivePricing | null, Error>,
): UseQueryResult<ComprehensivePricing | null, Error> {
  const enabled = !!(applicationId && lineOfBusinessName && answers && answersV2);
  return useQuery<ComprehensivePricing | null, Error>(
    [QueryId.comprehensivePricing, applicationId],
    async () => {
      if (!enabled) {
        return Promise.reject(new Error('Application information is missing to get comprehensive pricing.'));
      }
      return getComprehensivePricing(applicationId, answers, answersV2, lineOfBusinessName);
    },
    {
      ...options,
      enabled,
    },
  );
}
