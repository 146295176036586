import { ReactElement } from 'react';

import { Box, IconButton, Badge } from '@breathelife/mui';
import { ImageTypes } from '@breathelife/ui-components';

import styled from '../../../Styles/themed-styled-components';
import { Icon } from '../../../Components/Icons';

const IconBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  button {
    padding: 20px;

    &.Mui-focusVisible {
      outline: ${(props) => `1px solid ${props.theme.colors.selection.border.focus}}`};
    }
  }
`;

const bellIcon = (
  <Icon
    name='notificationBellIcon'
    variant='grey'
    stroke='none'
    imageType={ImageTypes.decorative}
    width='27px'
    height='27px'
  />
);

export function NotificationCentreIcon(props: {
  setIsNotificationCentreOpen: (value: boolean) => void;
  isNotificationCentreOpen: boolean;
  unreadNotificationsCount?: number;
}): ReactElement {
  const { isNotificationCentreOpen, setIsNotificationCentreOpen, unreadNotificationsCount } = props;
  const showBadgeContent = !!(unreadNotificationsCount && unreadNotificationsCount > 0);

  return (
    <IconBox>
      <IconButton onClick={() => setIsNotificationCentreOpen(!isNotificationCentreOpen)}>
        {showBadgeContent ? (
          <Badge
            badgeContent={unreadNotificationsCount >= 9 ? '9+' : unreadNotificationsCount.toString()}
            color='error'
          >
            {bellIcon}
          </Badge>
        ) : (
          bellIcon
        )}
      </IconButton>
    </IconBox>
  );
}
