import {
  AdminToolActionType,
  AdminToolResourceName,
  ApplicationMode,
  BlueprintCollectionOperator,
  ConditionBlueprintType,
  DateUnit,
  PdfDocumentType,
  DocumentVariant,
  FieldSizes,
  FileTemplateOrigin,
  HoldingForm,
  IconName,
  InsuranceModule,
  Language,
  LineOfBusinessName,
  MatchesConditionPropertyQuantifier,
  MathConditionOperator,
  MeasurementSystem,
  NavigationSidebarVariants,
  EmailNotificationTypes,
  NumberComparisonConditionOperator,
  NumericalDataType,
  OptionSize,
  OutcomeCode,
  ParticipantRoles,
  PaymentFrequency,
  PlatformType,
  ProductType,
  QuestionnaireBlueprintRenderOn,
  QuestionnairePreview,
  RuleReasonValue,
  StoredFileDocType,
  SubsectionVariant,
  SupportedBuyer,
  ThirdPartyIntegrationStatus,
  UserStatus,
  YesNoValue,
  ApplicationSearchMode,
  LeadStatusesKeys,
  LeadTableColumnKeys,
  CarrierSubmissionStatus,
  NotificationEventName,
  ESignAdhocStatus,
  ESignAdhocSignerStatus,
} from '@breathelife/types';

import { Translation } from '../../../Localization';
import { UserCategory, UserRole, UserType } from '../../../Services/Auth0';
import eSignatureStatus from './eSignatureStatus';
import engineValidation from './engineValidation';

const translation: Translation = {
  personalInformation: 'Personal Information',
  needsAnalysis: 'Needs Analysis',
  transactionFlow: 'Transaction Flow',
  assistedApplication: {
    assistedApplicationNavigationMenu: 'Assisted Application Menu',
    currentSection: 'Current section: ',
    currentSubsection: 'Current subsection: ',
    actionBanner: {
      readyForReview: {
        body: 'This application is complete and ready for review.',
        cta: 'Lock the application for review',
      },
      inReview: {
        body: 'The application is in review. In order to modify the proposal, set it back to edit mode. Once the document has been reviewed and validated, you may submit it for signature.',
        cta: 'Edit application',
      },
      sent: {
        body: 'This proposal has been sent for signature, and therefore cannot be edited. If you wish to change the content of the proposal, you must first cancel the signature process.',
        cta: 'Cancel the signature process',
      },
    },
    application: 'Application',
    title: 'Application',
    submitApplication: 'Submit Application',
    cannotSubmitIssueWithFiles: 'There is an issue with the files for this application',
    downloadApplicationPdf: 'Download the Application in PDF',
    seeSubmissionDetails: 'See submission details',
    sendToSignature: 'Send to Signature',
    submit: 'Submit',
    previousSection: 'Previous Section',
    nextSection: 'Next Section',
    sectionComplete: 'Section Complete',
    sectionWarning: 'Section Incomplete',
    cancelESignature: 'Confirm the Cancellation',
    signaturesDetails: 'Signatures details',
    identityVerification: 'InstantID®',
    licenseVerification: 'Licenses',
    success: 'Success',
    warning: 'Warning',
    eSignatureDetails: {
      status: eSignatureStatus.ceremonyStatus,
      header: 'Signature details',
      email: 'Email address',
      cellphone: 'Cell phone number',
      authMethod: 'Authentication Type',
      passcode: 'Passcode',
      show: 'Show',
      hide: 'Hide',
      emailInfo: 'This change is for the signature process only and will not be reflected in the application.',
      pdfGenerationInfo: 'Application documents are generating, please wait before sending.',
    },
    identityVerificationReport: 'InstantID® report',
    identityVerificationReportInfo:
      'Risk indicators(s) returned by InstantID® upon verification of the proposed insured information',
    displayFiles: 'Display Files',
    files: 'Files',
    attachingDocumentsNotRequired: 'Attaching documents is not required for {{lineOfBusiness}} products',
    documentsDrawer: {
      requiredFiles: 'Required Files',
      otherFiles: 'Other Files',
      uploadFile: 'Upload a file',
      noFilesUploaded: 'You have not uploaded any files yet',
      noRequiredFiles: 'There are no required files at this time',
      documentRequired: 'Document required',
    },
    recipientsDrawer: {
      title: 'Document Recipients',
    },
    signatureDrawer: {
      signatureRequired: 'Signature Required',
      signersInformation: 'Signers Information',
      documentsToSign: 'Documents To Sign',
      infoBoxMessage: 'All the documents will be sent to the appropriate signers for signatures',
    },
    multiInsuredTabs: {
      insuredPersons: 'Insured Persons',
      policyContract: 'Policy Contract',
      newProposedInsured: 'New Proposed Insured',
      addNewProposedInsured: 'Add New Proposed Insured',
      deleteProposedInsured: 'Delete Proposed Insured {{name}}',
    },
    // @deprecated
    attachmentUrl: 'attachment URL',
    paymentSubsection: {
      title: 'Premium Payment',
      alerts: {
        creditCardProcessing: 'We are currently verifying your payment information. Please check back soon.',
      },
      addPaymentMethod: {
        creditCard: 'Add a Credit Card',
      },
      paymentMethods: {
        creditCard: 'Credit Card',
      },
      previews: {
        creditCardNumber: 'Credit Card Number',
      },
    },
    fieldGroupCard: {
      error: 'Missing required information',
      expand: 'Details',
    },
    productWidget: {
      title: 'Your selection',
      empty: 'Your product selection is empty',
      products: 'Products',
      totalPremium: 'Total Premium',
      equivalentSingleAge: {
        title: 'Equivalent Single Age (ESA):',
        value: '{{age}} year old',
      },
      paymentFrequency: {
        [PaymentFrequency.monthly]: ' /month',
        [PaymentFrequency.quarterly]: ' /quarter',
        [PaymentFrequency.annually]: ' /year',
        [PaymentFrequency.semiAnnually]: ' /semi-annually',
      },
    },
    totalPremiumsWidget: {
      title: 'Price',
      totalPremium: 'Total Premium',
      paymentFrequency: {
        [PaymentFrequency.monthly]: ' /month',
        [PaymentFrequency.annually]: ' /year',
      },
    },
    comprehensivePricingWidget: {
      title: 'Your selection',
      contract: 'Contract',
      policyFees: 'Policy fees',
      totalPremium: 'Total premium',
      month: 'month',
      year: 'year',
      quarter: 'quarter',
      halfYear: 'half year',
      oneTime: 'one-time payment',
      subtotal: 'Sub-total',
      paymentModes: {
        annual: 'Annual',
        quarterly: 'Quarterly',
        monthly: 'Monthly',
        semiAnnual: 'Semi-annual',
        singlePremium: 'Single premium',
      },
      paymentModeDropdownLabel: 'Display premium as:',
      adoTooltipText:
        'The Additional Deposit Option is added in total premium based on the selected modality of payment.',

      fetchComprehensivePricingError:
        'We could not update the pricing for this application. Some of the required information may be missing.',
      partyRoles: {
        insured: 'Insured',
        owner: 'Owner',
      },
    },
    instantId: 'InstantID®',
    jetDecisionWidget: {
      title: 'Decisions',
      ruleReason: {
        [RuleReasonValue.fail]: 'Fail',
        [RuleReasonValue.pass]: 'Pass',
      },
      open: 'Open decisions widget',
    },
  },
  fileUrl: 'file URL',
  applications: 'Applications',
  products: 'Products',
  notes: 'Notes',
  history: 'History',
  new: 'New',
  edit: 'Edit',
  view: 'View',
  bestTimeToReach: 'Best time to reach',
  phone: 'Phone',
  email: 'Email',
  by: 'By',
  and: 'and',
  to: 'to',
  or: 'or',
  not: 'not',
  lead: 'Lead',
  true: 'True',
  false: 'False',
  lastUpdated: 'Last updated',
  lastUpdateOn: 'Last updated on',
  createdOn: 'Created on',
  characters: 'characters',
  coverageAmount: 'Coverage amount',
  recommendedCoverageAmount: 'Recommended coverage amount',
  perMonth: '/ month',
  pending: 'pending',
  percentCompleted: '{{percent}}% completed',
  missingPaymentInformation: 'Missing payment information',
  completed: 'completed',
  financialNeeds: 'Financial Needs',
  notApplicable: 'N/A',
  unassigned: 'Unassigned',
  me: 'Me',
  softwareByBreatheLife: 'A Software by Breathe Life',
  viewLogs: 'View Logs',
  pageTitles: {
    prefix: 'Pro Platform',
    home: 'Home',
    admin: 'Admin tools',
    userManagement: 'User management',
    documents: 'Documents',
    questionnaireManagement: 'Questionnaire management',
    productManagement: 'Product management',
    salesDecisionRulesManagement: 'Sales decision rules management',
    themeManagement: 'Theme management',
    settings: 'Settings',
    featuresManagement: 'Features management',
    logout: 'Log out',
    logs: 'Logs',
    questionnaireBuilder: 'Questionnaire builder',
    thirdPartyIntegrations: '3rd Party Integrations',
    pricingFieldIdentifiersManagement: 'Pricing field identifiers management',
    adHocSignatureRequests: 'Ad-hoc Signature Requests',
  },
  adminTabs: {
    platform: 'Platform',
    users: 'Users',
    questionnaire: 'Questionnaire',
    salesDecisionRules: 'Sales Decision Rules',
    firm: 'Firm',
    product: 'Product',
    theme: 'Theme',
    settings: 'Settings',
    features: 'Features',
    auditLogs: 'Audit Logs',
    audit: 'Audit',
    questionnaireBuilder: 'Questionnaire Builder',
    thirdPartyIntegrations: '3rd-Party Integrations',
    applicationSupport: 'Application Support',
    pricingFieldIdentifiers: 'Pricing Field Identifiers',
    adHocSignatureRequests: 'Ad-hoc Signature Requests',
  },
  authentication: {
    loggingIn: 'Logging in...',
    userBlockedTitle: 'This account seems to be deactivated',
    userBlockedText: "If you think that's an error, please contact your administrator.",
    generalErrorTitle: 'Something went wrong',
    generalErrorText:
      'There was an issue during the login process. If the problem persists, please contact your administrator.',
    softwareBy: 'A Software by',
  },
  user: {
    category: {
      [UserCategory.nonRegistered]: 'Non-Registered',
      [UserCategory.registered]: 'Registered',
    },
    role: {
      [UserRole.admin]: 'Admin',
      [UserRole.supportAdmin]: 'Support Admin',
      [UserRole.supportManager]: 'Support Manager',
      [UserRole.supportAnalyst]: 'Support Analyst',
      [UserRole.viewer]: 'Viewer',
      [UserRole.manager]: 'Manager',
      [UserRole.user]: 'User',
      [UserRole.superAdmin]: 'Super Admin',
      [UserRole.groupManager]: 'Group Manager',
    },
    type: {
      [UserType.captive]: 'Captive',
      [UserType.nonCaptive]: 'Non-Captive',
    },
    status: {
      [UserStatus.active]: 'Active',
      [UserStatus.blocked]: 'Blocked',
      [UserStatus.invitationPending]: 'Invitation Pending',
    },
  },
  language: {
    [Language.en]: 'English',
    [Language.fr]: 'French',
  },
  documentOrigin: {
    [FileTemplateOrigin.BLGenerated]: 'BL Generated',
    [FileTemplateOrigin.CarrierGenerated]: 'API-Provided',
    [FileTemplateOrigin.UserProvided]: 'User-Provided',
    [FileTemplateOrigin.ExternalStatic]: 'Hosted Externally',
    [FileTemplateOrigin.GCPBucket]: 'Stored in Cloud Storage',
  },
  documentType: {
    [StoredFileDocType.Attachment]: 'Attachment',
    [StoredFileDocType.Illustration]: 'Illustration',
  },
  documentVariant: {
    [DocumentVariant.ModifiedEndowmentContract]: 'Modified Endowment Contract',
    none: 'None',
  },
  participantRole: {
    [ParticipantRoles.AGENT]: 'Advisor',
    [ParticipantRoles.INSURED]: 'Insured',
    [ParticipantRoles.OWNER_PERSON]: 'Owner',
    [ParticipantRoles.PAYER]: 'Payor',
    [ParticipantRoles.SECONDARY_PAYER]: 'Payor',
    [ParticipantRoles.LEGAL_GUARDIAN]: 'Legal Guardian',
    [ParticipantRoles.TRUSTEE]: 'Trustee',
    [ParticipantRoles.SOLE_PROPRIETOR]: 'Sole Proprietor',
    [ParticipantRoles.SIGNING_AUTHORITY]: 'Signing Authority',
    [ParticipantRoles.OFFICER]: 'Officer',
    [ParticipantRoles.TRANSLATOR]: 'Translator',
    [ParticipantRoles.CONTRIBUTOR]: 'Contributor',
    [ParticipantRoles.SECONDARY_CONTRIBUTOR]: 'Contributor',
    [ParticipantRoles.ANNUITANT]: 'Annuitant',
    [ParticipantRoles.OWNER_ORGANIZATION]: 'Owner Organization',
    [ParticipantRoles.PRIMARY_BENEFICIARY]: 'Primary Beneficiary',
    [ParticipantRoles.CONTINGENT_BENEFICIARY]: 'Contingent Beneficiary',
  },
  usersListTable: {
    fullName: 'User name',
    email: 'Email address',
    role: 'Role',
    lastConnection: 'Last connection',
    status: 'Status',
    isSso: 'is SSO user',
    leadsAssigned: 'Leads assigned',
    statusFilter: 'All status',
    emptySearchResult: {
      title: 'No results found!',
      body: "Please adjust your search or filter to find what you're looking for.",
    },
  },
  applicationSupport: {
    tabs: {
      overview: 'Application Overview',
      underwriting: 'Underwriting',
      eSignature: 'eSignature',
      associatedFiles: 'Associated Files',
    },
    pages: {
      overview: 'Application Overview',
      underwriting: 'Underwriting View',
      audit: 'Audit View',
      eSignature: 'eSignature View',
      associatedFiles: 'Associated Files',
    },
    header: {
      applicationId: 'Application UUID: {{applicationId}}',
      policyNumber: 'Policy Number: {{refNo}}',
    },
    badge: {
      genericSuccessStatus: {
        [CarrierSubmissionStatus.success]: 'Success',
        [CarrierSubmissionStatus.manuallySent]: 'Manually Submitted',
        [CarrierSubmissionStatus.error]: 'Error',
      },
    },
    applicationOverview: {
      applicationUUID: 'Application UUID',
      policyNumber: 'Policy Number',
      createdDate: 'Created Date',
      lastModifiedDate: 'Last Modified Date',
      applicationStatus: 'Application Status',
      pdfGenerationStatus: 'PDF Generation Status',
      submissionStatus: 'Carrier Submission Status',
      totalNumberOfInsured: 'Total Number of Insured',
      outcomeForInsured: 'Outcome For Insured {{index}}',
      actionsWidgetTitle: 'Application Actions',
      actionsWidgetItems: {
        resetApplicationStatus: 'Reset Application Status',
        downloadSubmissionFiles: 'Download Submission Files',
        applicationCompletedTitle: 'An application that has been submitted successfully cannot be reset',
        applicationSubmissionFilesNotAvailable:
          'Can only download submission files of applications that have been submitted to the carrier successfully',
        manualSubmission: 'Manually submit the application',
        manualSubmissionAllowed:
          'Download the files and then you have to give them to the carrier yourself. The application will be marked as manually submitted. This will also stop the submission retry mechanism for this application.',
        manualSubmissionNotAllowed:
          'Can only manually submit applications that have the "Submission in progress" status.',
      },
      currentAssignment: 'Current Assignment',
      mode: 'Mode',
      lineOfBusiness: 'Line of Business',
    },
    eSignature: {
      eSignCeremony: 'eSign Ceremony {{ceremonyNumber}}',
      noESignCeremonyAvailable: 'No eSign ceremony is available yet',
      summary: 'Summary',
      provider: 'Provider',
      signatureCeremonyStatus: 'Signature Ceremony Status',
      ceremonyFiles: 'Ceremony Files',
      fileType: 'File type',
      fileName: 'File name',
      lastUpdateDate: 'Creation/Update Date',
      activeParticipants: 'Active Participants',
      participant: 'Participant {{participantNumber}}',
      emailAddress: 'Email Address',
      assignedRoles: 'Assigned Role(s)',
      signatureStatus: 'Signature Status',
      noParticipantsFound: 'No participants found',
      activeCeremony: '(Active)',
      package: {
        id: '{{provider}} Package ID',
        copy: 'Copy {{provider}} Package ID',
      },
    },
    applications: {
      emptySearchResult: {
        title: 'No results found!',
        body: "Please adjust your search to find what you're looking for.",
      },
      moreActions: {
        resetApplicationStatus: 'Reset application status',
      },
    },
    underwritingReports: {
      copy: 'Copy',
      insured: 'Insured {{insuredNumber}}',
      report: 'Report',
      protected: 'This information is protected',
      noReportsFound: 'No reports found',
    },
    associatedFiles: {
      noFilesFound: 'No files found',
      fileType: 'File Type',
      fileName: 'File Name',
      fileSize: 'File Size',
      createdAt: 'Creation/Upload Date',
      mimeType: 'MIME Type',
    },
  },
  leadsListTable: {
    active: 'Active Leads',
    archived: 'Archived Leads',
    filters: 'filters',
    status: 'All statuses',
    applicationStatus: 'Application status',
    signatureStatus: 'Signature status',
    search: 'Search',
    addNew: 'Add New',
    headerAriaLabel: 'leads filters',
  },
  applicationTable: {
    active: 'Active Applications',
    archived: 'Archived Applications',
    search: {
      [ApplicationSearchMode.application]: 'Enter an Application ID or Policy #',
      [ApplicationSearchMode.esign]: 'Enter an eSign provider package ID',
    },
    id: 'Application ID',
    refNo: 'Policy Number',
    createdAt: 'Creation date',
    updatedAt: 'Updated at',
    insuredCount: 'Insured',
    leadStatus: 'Status',
    filterByLeadStatus: 'Filter by status',
    searchMode: 'Search',
    applicationSearchModes: {
      [ApplicationSearchMode.application]: 'Application',
      [ApplicationSearchMode.esign]: 'eSign',
    },
    mode: 'Mode',
    lineOfBusiness: 'Line of Business',
  },
  leadTableColumns: {
    [LeadTableColumnKeys.actions]: 'Actions',
    [LeadTableColumnKeys.applicationMode]: 'Mode',
    [LeadTableColumnKeys.applicationRefNo]: 'Application Ref No.',
    [LeadTableColumnKeys.assignedTo]: 'Assigned to',
    [LeadTableColumnKeys.bestTimeToReach]: 'Best time to reach',
    [LeadTableColumnKeys.clientName]: 'Client name',
    [LeadTableColumnKeys.connected]: 'Connected',
    [LeadTableColumnKeys.contacted]: 'Contacted',
    [LeadTableColumnKeys.creationDate]: 'Creation date',
    [LeadTableColumnKeys.email]: 'Email',
    [LeadTableColumnKeys.lineOfBusiness]: 'Line of Business',
    [LeadTableColumnKeys.phoneNumber]: 'Phone number',
    [LeadTableColumnKeys.ownerDetails]: 'Primary Owner',
    [LeadTableColumnKeys.progress]: 'Progress',
    [LeadTableColumnKeys.proposedInsuredAndAnnuitant]: 'Proposed Insured/Annuitant',
    [LeadTableColumnKeys.refNoIncrement]: 'Application #',
    [LeadTableColumnKeys.referralCode]: 'Referral',
    [LeadTableColumnKeys.signatureStatus]: 'Signature status',
    [LeadTableColumnKeys.status]: 'Status',
    [LeadTableColumnKeys.updatedAt]: 'Updated at',
  },
  leadStatuses: {
    [LeadStatusesKeys.new]: 'New',
    [LeadStatusesKeys.invited]: 'Invited',
    [LeadStatusesKeys.started]: 'Started',
    [LeadStatusesKeys.questionnaireStarted]: 'Questionnaire started',
    [LeadStatusesKeys.analysisStarted]: 'Analysis started',
    [LeadStatusesKeys.analysisComplete]: 'Analysis complete',
    [LeadStatusesKeys.toContact]: 'To contact',
    [LeadStatusesKeys.progressing]: 'Progressing',
    [LeadStatusesKeys.finalized]: 'Finalized',
    [LeadStatusesKeys.applicationInProgress]: 'Application in progress',
    [LeadStatusesKeys.applicationStarted]: 'Application started',
    [LeadStatusesKeys.applicationCompleted]: 'Application completed',
    [LeadStatusesKeys.applicationSubmitted]: 'Application submitted',
    [LeadStatusesKeys.submissionInProgress]: 'Submission in progress',
  },
  applicationModes: {
    [ApplicationMode.digital]: 'Digital',
    [ApplicationMode.paper]: 'Paper',
  },
  applicationLineOfBusiness: {
    [LineOfBusinessName.life]: 'Life',
    [LineOfBusinessName.annuity]: 'Annuity',
    [LineOfBusinessName.legacyLife]: 'Life',
    [LineOfBusinessName.legacyAnnuity]: 'Annuity',
    unknown: 'Unknown',
  },
  pagination: {
    results: {
      singular: 'Result',
      plural: 'Results',
    },
    perPage: 'Rows per page',
  },
  mgaGroup: {
    default: 'None',
  },
  userMenu: {
    shareMyPublicLink: 'Share my Public Link',
    myProfile: 'My profile',
    notifications: 'Email notifications',
    logout: 'Logout',
    filesAndDocuments: 'Files and documents',
    agentLicenses: 'Licenses',
  },
  cta: {
    send: 'Send',
    save: 'Save',
    edit: 'Edit',
    block: 'Block',
    cancel: 'Cancel',
    close: 'Close',
    delete: 'Delete',
    assign: 'Assign',
    extractAllLeadData: 'Extract all lead data',
    clear: 'Clear',
    moreOptions: 'More Options',
    copy: 'Copy',
    copyApplication: 'Copy Application',
    assignTo: 'Assign to',
    archive: 'Archive',
    unarchive: 'Unarchive',
    archiveLead: 'Archive lead',
    archiveApplication: 'Archive application',
    unarchiveLead: 'Unarchive lead',
    unarchiveApplication: 'Unarchive application',
    disabled: 'Disabled',
    deleteUser: 'Delete user',
    sendNeedsAnalysis: 'Send Needs Analysis',
    sendAReminder: 'Send a reminder',
    sendTheReminder: 'Send the reminder',
    resendNeedsAnalysis: 'Resend Needs Analysis',
    launchApplication: 'Launch application',
    selectQuestionnaire: 'Select a questionnaire',
    continue: 'Continue',
    needAnalysisDetails: 'Needs Analysis Details',
    addNew: 'Add New',
    createUser: 'Create user',
    downloadFile: {
      singular: 'Download file',
      plural: 'Download files',
    },
    downloadDocument: 'Download document',
    uploadFilledDocument: 'Upload a filled document',
    import: 'Import',
    cancelESignatureProcess: 'Cancel the signature process',
    signaturesComplete: 'The application is signed by all the persons concerned.',
    view: 'View',
    reset: 'Reset',
  },
  dragAndDrop: {
    dropFile: "Drag 'n' drop some files here or double click for file selector",
    acceptedFiles: 'Accepted files',
    bytes: 'bytes',
  },
  notifications: {
    unableToSendToSignature: 'The application could not be sent to signature.',
    documentArchived: 'Document successfully archived',
    eSignatureRequestSent: 'Signature request successfully sent.',
    eSignatureCancelled: 'Signature request cancelled.',
    cancelESignCeremonyFail: 'Cancel eSign ceremony failed',
    cannotCancelCompletedESignCeremony: 'Cannot cancel a completed eSign ceremony',
    linkCopied: 'Link Copied!',
    dataCopied: '{{data}} Copied!',
    imageUploadSuccess: 'Image successfully uploaded',
    profileUpdated: 'Profile updated!',
    invitationSent: 'Invitation sent!',
    userSuccessfullyCreated: 'An invitation to access the platform has been successfully sent to {{user}}!',
    userSuccessfullyDeleted: 'User has been deleted',
    userInvitationEmailSuccessfullySent: {
      title: 'Invitation email sent successfully!',
      message: 'An invitation to access the platform has been successfully sent to {{name}}!',
    },
    failedToValidateRequestTitle: 'Validation Failed',
    failedToValidateRequest:
      "We've identified that the personal information has been modified since the last InstantID verification. Please perform a new InstantID verification.",
    updatedUserNotificationPreferences: 'Email notification preferences updated!',
    deleteLeadSuccess: 'Lead successfully deleted!',
    deleteApplicationSuccess: 'Application successfully deleted!',
    updateUser: 'User successfully updated!',
    themeUpdateSuccess: 'The theme was successfully updated',
    badRequestGeneral: 'Provided information is invalid',
    createUserConflict: 'This email address is already taken.',
    forbiddenGeneral: 'You do not have the required permission to complete this action.',
    forbiddenInvitationEmail: 'There was an error sending the email invitation.',
    forbiddenGetLead: 'You do not have the required permission to see this lead.',
    submittedAndSignedApplication: 'Application has been submitted and signed successfully!',
    signedApplication: 'Application has been signed successfully!',
    failedToGetNotes: 'There was an error getting the lead notes.',
    failedToUpdateNotes: 'There was an error updating the lead notes. Please verify the entered information is valid.',
    failedToUpdateisDeclarationSent: 'There was an error updating the application representative declaration',
    failedToCopyAgentLink: 'Failed to copy agent link!',
    failedToCopyPrivateLink: 'Failed to copy private link!',
    failedToLaunchCoBrowsing: 'Failed to launch application!',
    failedToSubmitApplicationAndRedirect: 'Failed to launch application!',
    failedToAddInsured: 'Failed to add proposed insured.',
    failedToRemoveInsured: 'Failed to remove proposed insured.',
    failedToAssignLeads: 'There was an error while assigning the lead. Please try again',
    failedToAssignApplications: 'There was an error while assigning the application. Please try again',
    failedToCreateLead: 'There was an error creating the lead. Please verify the entered information is valid.',
    failedToUpdateLead: 'There was an error updating the lead. Please verify the entered information is valid.',
    failedToGetLead: 'There was an error retrieving the lead information.',
    failedToSendInvitationEmail: 'There was an error sending the email invitation. Please try again.',
    failedToUpdateUser: 'There was an error while updating the user. Please verify the entered information is valid.',
    failedToDeleteUser: 'There was an error while deleting the user. Please try again',
    failedToLoadProducts: 'There was an error retrieving the products',
    failedToLoadJetDecisionOutcomes: 'There was an error retrieving the data for "jet decision widget"',
    failedToFetchQuotes: 'An error occurred while retrieving the quotes',
    failedToFetchCarrierQuestionnaire: 'An error occurred while retrieving the carrier questionnaire',
    failedToFetchESignatureCeremonies: 'An error occurred while retrieving the eSignature ceremonies.',
    failedToFetchAssociatedFiles: 'An error occurred while retrieving the associated files.',
    failedToSubmitApplication: 'An error occurred while attempting to submit the application',
    failedToSubmitApplicationRefreshPage: 'The application is out of date. Please refresh the page.',
    failedToSignApplication: 'An error occurred while attempting to sign the application',
    submittedButFailedToSignApplication: 'Application submitted but failed to sign the application',
    failedToUpdateApplicationAnswers: 'An error occurred while saving the application answers',
    updatedADOMinMax: 'ADO min/max updated',
    fetchingADOMinMax: 'Retrieving ADO min/max',
    failedToFetchADOMinMax: 'An error occurred while retrieving ADO min/max',
    failedToFetchESA: 'An error occurred while retrieving Equivalent Single Age (ESA)',
    failedDueToMissingNodeIds: 'Missing required information.',
    failedToFetchPdfUrl: 'An error occurred while retrieving the pdf URL',
    failedToUpdateCoverageAmount: 'An error occurred while saving the coverage amount',
    failedToCreateApplication: 'An error occurred while creating the application',
    failedToLoadTheme: 'There was an error retrieving the theme',
    failedToUpdateTheme: 'There was an error while updating the theme',
    settingsUpdateSuccess: 'The settings were successfully updated',
    failedToLoadSettings: 'There was an error retrieving the settings',
    failedToCreateAPreview: 'There was an error trying to setup a questionnaire preview',
    failedToUpdateSettings: 'There was an error while updating the settings',
    failedToUpdateNotificationPreferences: 'There was an error while updating your email notification preferences.',
    failedToSaveQuestionnaire: 'There was an error while saving the questionnaire.',
    saveQuestionnaireSuccess: 'Questionnaire updated successfully',
    fieldCopiedToClipboard: 'Field copied to the clipboard.',
    fieldGroupCopiedToClipboard: 'Field group copied to the clipboard.',
    subsectionCopiedToClipboard: 'Subsection copied to the clipboard.',
    sectionCopiedToClipboard: 'Section copied to the clipboard.',
    failedToPublishQuestionnaire: 'There was an error while publishing the questionnaire',
    publishQuestionnaireSuccess: 'Questionnaire published successfully',
    newDraftQuestionnaireSuccess: 'Draft questionnaire created successfully',
    failedToCreateNewDraftQuestionnaire: 'There was an error while creating a new draft questionnaire',
    addFieldSuccess: 'Field added',
    addQuestionSuccess: 'Field group added',
    addSubsectionSuccess: 'Subsection added',
    addSectionSuccess: 'Section added',
    failedToFetchNodeIds: 'There was an error retrieving all nodeIds',
    failedToFetchEntity: 'There was an error retrieving the {{entity}}',
    failedToCreateEntity: 'There was an error creating the {{entity}}',
    failedToUpdateEntity: 'There was an error updating the {{entity}}',
    failedToDeleteEntity: 'There was an error deleting the {{entity}}',
    failedToFetchUtilitiesTypeDeclarations: 'There was an error fetching utilities type declarations',
    entityCreationSuccess: 'Successfully created the {{entity}}',
    entityUpdateSuccess: 'Successfully updated the {{entity}}',
    entityDeleteSuccess: 'Successfully deleted the {{entity}}',
    premiumWasUpdated: 'The premium has been updated',
    fetchingPremium: 'Fetching premium',
    debugToolbar: {
      failedAutoFillApplicationAnswers:
        'An error occurred when trying to fill information until subsectionId: {{subsectionId}}',
    },
    // @deprecated
    failedToUploadAttachment: 'There was an error while uploading the attachment',
    failedToFetchAttachments: 'There was an error while fetching the attachments',
    failedToDeleteAttachment: 'There was an error while deleting the attachment',
    // end - @deprecated
    failedToFetchUploadedFiles: 'There was an error while fetching the uploaded files',
    failedToFetchUploadedFile: 'There was an error while fetching the uploaded file',
    fileUploadSuccess: 'File successfully uploaded',
    fileUploadError: {
      general: 'There was an error while uploading the file',
      UNACCEPTABLE_MIME_TYPE: 'The given file format is unacceptable',
      UNACCEPTABLE_FILE_EXTENSION: 'The given file extension is unacceptable',
    },
    fileDeleteSuccess: 'File successfully deleted',
    fileDeleteError: 'There was an error while deleting the file',
    failedToFetchFileTemplates: 'There was an error while fetching the file conditions',
    payment: {
      failedToLoadPaymentInformation: 'Error while loading the payment information.',
      failedToSubmitPaymentMethod: 'Error while submitting the payment information.',
      failedToUpdatePaymentInformation: 'Error while updating the payment information',
    },
    failedToExportData: 'Error while exporting data',
    exportDataSuccess: 'Successfully exported data to file',
    importedDataSuccess: 'Successfully imported data from file',
    failedToImportData: 'Error while importing data from file',
    identityVerificationToast: {
      success: {
        title: 'InstantID® has been verified',
        description: 'InstantID® has verified and validated the proposed insured’s information',
      },
      info: {
        minor: {
          title: 'InstantID® verification is not required',
          description: 'InstantID® verification is not required for minors',
        },
      },
      warning: {
        title: 'Issue(s) detected',
        description: 'InstantID® call was successful but require your attention',
      },
      error: {
        title: 'InstantID problem occurred',
        description: 'A problem occurred and the InstantID verification could not be completed',
      },
      validationErrors: {
        title: 'An InstantID validation error has occured',
        firstName: {
          empty: 'The first name field is required.',
        },
        lastName: {
          empty: 'The last name field is required.',
        },
        DOB: {
          year: {
            min: 'The year of birth field cannot be before 1900.',
          },
          month: {
            min: 'The month of birth field cannot be less than one.',
            max: 'The month of birth field cannot be more than 12.',
          },
          day: {
            min: 'The day of birth field cannot be less than one.',
            max: 'The day of birth field cannot be more than 31.',
          },
        },
        telephone: {
          classification: {
            type: 'The telephone classification type is invalid.',
            empty: 'The telephone classification type is required.',
          },
          areaCode: {
            pattern: 'Please ensure that the telephone area code only contains digits.',
            max: 'Please ensure that the telephone area code does not exceed three digits.',
            empty: 'The telephone area code is required.',
          },
          exchange: {
            pattern: 'Please ensure that the telephone exchange number only contains digits.',
            max: 'Please ensure that the telephone exchange number does not exceed three digits.',
            empty: 'The telephone exchange number is required.',
          },
          number: {
            pattern: 'Please ensure that the telephone line number only contains digits.',
            max: 'Please ensure that the telephone line number does not exceed four digits.',
            empty: 'The telephone line number is required.',
          },
          extension: {
            pattern: 'Please ensure that the telephone extension code only contains digits.',
            max: 'Please ensure that the telephone extension code does not exceed four digits.',
          },
        },
        currentAddress: {
          idPattern: 'Please ensure that the current address id matches the correct pattern.',
          street: {
            number: {
              pattern: 'Please ensure that the current address street number contains only digits.',
              max: 'Please ensure that the current address street number does not exceed four digits.',
            },
            name: {
              pattern: 'Please ensure that the current address street name does not contain any special characters.',
              max: 'Please ensure that the current address street name does not exceed 20 characters.',
            },
            address1: {
              max: 'Please ensure that the current street address 1 field does not exceed 20 characters.',
              empty: 'The current street address 1 field is required.',
            },
            address2: {
              max: 'Please ensure that the current street address 2 field does not exceed 20 characters.',
            },
          },
          city: {
            max: 'Please ensure that the current address city does not exceed 20 characters.',
            empty: 'The current address city field is required.',
          },
          state: {
            max: 'Please ensure that the current address state does not exceed two characters.',
            empty: 'The current address state field is required.',
          },
          zip5: {
            max: 'Please ensure that the current address zip5 does not exceed five characters.',
            empty: 'The current address zip5 field is required.',
          },
          county: {
            pattern: 'Please ensure that the current address county does not contain any special characters.',
            max: 'Please ensure that the current address county does not exceed 15 characters.',
          },
          country: {
            max: 'Please ensure that the current address country does not exceed 15 characters.',
          },
          unitNumber: {
            pattern: 'Please ensure that the current address unit number contains only digits.',
            max: 'Please ensure that the current address unit number does not exceed five digits.',
          },
          postalCode: {
            max: 'Please ensure that the current address postal code does not exceed five characters.',
          },
        },
        ssn: {
          empty: 'The social security number is required.',
        },
      },
      serverErrors: {
        title: 'An InstantID server issue has occured.',
        timeoutError: 'There was a server timeout error during the InstantID verification.',
        addressError: 'There was a server address error during the InstantID verification.',
        processError: 'There was a server processing error during the InstantID verification.',
        applicationNotFound: 'Application could not be found during the InstantID verification.',
      },
      timeoutError: {
        title: 'InstantID server timeout',
        description: 'Could not complete the identity verification check due to server timeout.',
      },
      unexpectedError: 'An unexpected error has occured',
      failedToCheckConsent: 'InstantID consent has not been accepted',
    },
    deleteProposedInsuredSuccess: 'Insured deleted successfully',
    questionnaireBuilderPdfUploaded: 'PDF successfully uploaded',
    failedToGetAgentLicenses: 'There was an error while fetching the agent licenses',
    copyApplication: {
      copyApplicationSuccess:
        'Application #{{refNoIncrement}} has been copied successfully. Application #{{copyRefNoIncrement}} has been created.',
      failedToCopyApplication:
        'Application #{{refNoIncrement}} cannot be copied due to technical errors. Please try again.',
      copyApplicationMissingConfigThresholdInDays: 'Missing config for application copy threshold in days.',
      copyApplicationIsDisabledForLineOfBusiness: "Can't copy for that line of business",
      copyApplicationMissingConfigStartDate: 'Missing config for application copy start date.',
      copyApplicationDisabledThresholdInDays:
        'Application #{{refNoIncrement}} cannot be copied due to the {{thresholdInDays}} days threshold limit to copy an application.',
      copyApplicationDisabledStartDate:
        'Application #{{refNoIncrement}} cannot be copied due to an outdated questionnaire version.',
      copyApplicationDisabledAnnuityLineOfBusiness:
        'Application #{{refNoIncrement}} cannot be copied because the selected Line of Business. ',
      copyApplicationDisabledAlreadySubmitted:
        'Application #{{refNoIncrement}} cannot be copied because it has already been submitted',
    },
    saveApplicationSuccess: 'Application saved successfully',
    dataExtractSuccess: 'Leads data extract request registered.',
    dataExtractFailure: 'Request not registered. Please try again later.',
    getProductsWidgetTotalPremiumsFailure: 'Total premium could not be updated',
    getComprehensivePricingFailure: 'Pricing could not be updated',
    getUnderwritingReportsFailure: "Underwriting Reports for applicationId: '{{applicationId}}' could not be retrieved",
    getSupportApplicationFailure: "Application '{{applicationId}}' could not be retrieved",
    getSupportApplicationSubmittedFilesFailure:
      "Application '{{applicationId}}' submitted files could not be retrieved",
    applicationSupport: {
      resetStatus: {
        success: 'Application reset was successful',
        error: {
          default: 'We encountered an error while attempting to reset the application. Please try again',
          missingApplicationId: 'Missing application ID',
          invalidApplicationId: 'Invalid application ID',
          applicationNotFound: 'Could not find an application with ID {{applicationId}}',
          noCompletedESignCeremonies: 'There are no completed e-sign ceremonies for the application',
          notMarkedAsSubmitted: 'The application is not submitted. Cannot reset status',
          applicationCompleted: 'The application has been completed. Cannot reset status',
        },
      },
    },
    failedToFetchQuestionnaires: 'There was an error while fetching the questionnaires',
    failedToFetchQuestionnairesForQuestionnaireVersionId:
      'There was an error while fetching the questionnaires for a questionnaire version id',
    failedToFetchLinesOfBusiness: 'There was an error while fetching the lines of business',
    failedToFetchUserGroups: 'There was an error while fetching user groups',
    failedToUpdateUserGroupsForUser: 'There was an error updating groups.',
    failedToCreateNewQuestionnaire: 'There was an error while creating a new questionnaire',
    failedToFetchThemes: 'There was an error while fetching the themes',
    failedToFetchDefaultTheme: 'There was an error while fetching the default theme',
    unprocessablePDFHasFormFieldsThatRequireAppearances: 'The PDF has form fields that require appearances.',
    conflictFileAlreadyExists: 'A file for that type of Document has already been uploaded. Try refreshing the page.',
    failedToFetchPricingFieldIdentifiers: 'There was an error while fetching pricing field identifiers',
    failedToUpdatePricingFieldIdentifiers: 'There was an error while updating pricing field identifiers',
    updatePricingFieldIdentifierSuccess: 'Pricing field identifiers were successfully updated',
    failedToFetchAllAdHocSignatureRequests: 'There was an error while fetching ad-hoc signature requests',
    failedToFetchAdHocSignatureRequest: 'There was an error while fetching the ad-hoc signature request',
    failedToCloseAdHocSignatureRequest: 'There was an error while closing the ad-hoc signature request',
    failedToCancelAdHocSignatureRequest: 'There was an error while cancelling the ad-hoc signature request',
    successfullyCancelledAdHocSignatureRequest: 'Successfully cancelled the ad-hoc signature request',
    successfullyClosedAdHocSignatureRequest: 'Successfully closed the ad-hoc signature request',
    storedFilesDoNotExist: {
      applicationSubmitted: {
        title: 'Uploaded files not found',
        message: 'This application was submitted successfully and the uploaded files have expired.',
      },
      applicationNotSubmitted: {
        title: 'Uploaded files not found',
        message: 'The uploaded files in this application have expired. Please upload them again.',
      },
    },
  },
  leadDetailDrawer: {
    newProfile: 'New profile',
    connectedWithClient: 'Connected with client',
    checkSubscriptionStatus: 'Check the subscription status',
    isDeclarationSent: 'I have sent my Representative Declaration',
    history: {
      showSystemStatus: 'Show system status',
      header: {
        date: 'Date',
        user: 'Agent',
        event: 'Event',
      },
      statuses: {
        ['statusUpdated/Unready']: 'Draft',
        ['statusUpdated/New']: 'New',
        ['statusUpdated/Invited']: 'Invited',
        ['statusUpdated/PlanFinderStarted']: 'Plan finder started',
        ['statusUpdated/PlanFinderCompleted']: 'Plan finder completed',
        ['statusUpdated/Engaged']: 'Engaged',
        ['statusUpdated/TransactionFlowStarted']: 'Transaction flow started',
        ['statusUpdated/TransactionFlowCompleted']: 'Transaction flow completed',
        ['statusUpdated/Qualified']: 'Qualified',
        archived: 'Archived',
        unarchived: 'Unarchived',
        deleted: 'Deleted',
        emailSent: 'Email sent',
        ['assist/ApplicationAccessed']: 'Application Accessed',
        assigned: 'Assigned',
        unassign: 'Unassigned',
      },
      statusTypes: {
        statusUpdate: 'Status update',
        archive: 'Archive',
        communication: 'Communication',
        assisted: 'Assisted',
        dispatch: 'Dispatch',
      },
    },
  },
  userDetailDrawer: {
    newProfile: 'New profile',
    emailAddress: 'Email address',
  },
  week: {
    ['0']: { full: 'Monday', short: 'Mon' },
    ['1']: { full: 'Tuesday', short: 'Tue' },
    ['2']: { full: 'Wednesday', short: 'Wed' },
    ['3']: { full: 'Thursday', short: 'Thu' },
    ['4']: { full: 'Friday', short: 'Fri' },
    ['5']: { full: 'Saturday', short: 'Sat' },
    ['6']: { full: 'Sunday', short: 'Sun' },
  },
  inputs: {
    firstName: 'First name',
    lastName: 'Last name',
    documentName: 'Document Name',
    enDocumentName: 'Document Name in English',
    frDocumentName: 'Document Name in French',
    documentOrigin: 'Document Origin',
    documentType: 'Document Type',
    documentIdentifier: 'Document Identifier',
    externalDocumentUrl: 'External URL',
    documentVariant: 'Variant',
    preferredName: 'Preferred name',
    email: 'Email',
    phoneNumber: 'Phone number',
    cellNumber: 'Cell phone number',
    role: 'Role',
    category: 'Category',
    type: 'Type',
    group: 'Groups',
    userId: 'User Id',
    zipCode: 'Zip Code',
    postalCode: 'Postal Code',
    state: 'State',
    province: 'Province',
    securityDisclosure: 'Securities Disclosure',
    coverageAmount: 'Custom coverage amount',
    language: 'Language of correspondence',
    passcode: 'Passcode',
  },
  validation: {
    ...engineValidation,
    optional: 'optional',
    error: {
      fname: 'Invalid $t(inputs.firstName)',
      lname: 'Invalid $t(inputs.lastName)',
      pname: 'Invalid $t(inputs.preferredName)',
      email: 'Invalid $t(inputs.email)',
      required: 'This is a required field',
      fieldIsRequired: '{{field}} is a required field',
      unique: 'Value must be unique',
      phoneNumber: 'Invalid $t(inputs.phoneNumber)',
      cellNumber: 'Invalid $t(inputs.cellNumber)',
      zipCode: 'Zip Code must be in the following format: 99999 or 99999-9999',
      postalCode: 'Postal Code must be in the following format: A1A 1A1',
      phoneNumberAreaCode: 'Invalid area code',
      coverageLimitMax: 'The coverage amount exceeds the maximum of {{amount}}. Please adjust this amount accordingly.',
      coverageLimitMin: 'The minimum coverage amount is {{amount}}. Please adjust this amount accordingly.',
      nodeIdInList: 'Selected field node ID is in list of field node IDs',
      nodeIdNotInList: 'Selected field node ID not in list of field node IDs',
      mismatchedCollectionNodeIds: 'Field node IDs belong to different collections',
      nodeIdIsNumberField: 'Selected field is a number',
      nodeIdIsNotNumberField: 'Selected field is not a number',
      minValue: 'Minimum value must be less than maximum value',
      maxValue: 'Maximum value must be more than minimum value',
      positive: 'Value must be positive',
      fileType: 'Invalid file type',
      fileName: 'Invalid file name',
      modalFactor: 'Invalid modal factor',
      duplicateSignersEmail: 'Email must be unique for each of the signers',
      duplicateAdvisorsEmail: 'The advisor email must be different from the other signers',
      duplicateSignersInfo: 'First name, last name, and email must be unique for each of the signers',
      productIdAlreadyExists: 'There is already a product with this ID',
      percentMax: 'Percent cannot exceed 100%',
      ruleIdentifier: 'Identifier can be a single descriptor, or a sequence of descriptors separate by . or -',
    },
  },
  alerts: {
    errorDetails: 'Error details',
  },
  popper: {
    identityVerification: {
      responses: {
        identityVerificationCheck: {
          body: `Run the proposed insured’s information through InstantID® before submitting the application`,
          checkWithInstantIdButton: 'Check with InstantID',
        },
        identityVerificationTimeoutRetry: {
          body: `Run the proposed insured’s information through InstantID® before submitting the application`,
          checkWithInstantIdButton: 'Retry InstantID®',
        },
        identityVerificationFailedRetry: {
          body: 'InstantID® has identified at least one issue when verifying this person’s information',
          reportLink: 'See InstantID® report',
          checkWithInstantIdButton: 'Retry InstantID®',
        },
        identityVerificationSuccess: {
          body: 'InstantID® has verified and validated the proposed insured’s information',
        },
      },
    },
    licenseVerification: {
      header: 'The license has been verified',
      status: {
        neutral: 'Your license cannot be verified until the policy owner’s province of residence has been chosen',
        valid: 'License has been verified and validated in order to continue with this application.',
        invalidJurisdiction: `The license has been verified but you must be licensed to sell in the owner's province of residence. However, you can continue and submit this policy`,
        expired:
          'The license has been verified but needs to be renewed. However, you can continue and submit this application',
        invalid: 'License cannot be verified',
      },
    },
  },
  modals: {
    assistedApplication: {
      submissionDetails: {
        modalTitle: 'Submission details',
        confirmationNumber: 'Confirmation number for your records:',
        digitalSignatureKey: 'Digital Signature Key',
      },
      submitApplicationView: {
        submissionView: {
          responses: {
            accepted: {
              title: 'The application was successfully submitted!',
              body: `<p>Congratulations! The policy is now active.</p>
              <p>The consumer will receive the next steps by email within the next several business days.</p>`,
            },
            referred: {
              title: 'The application has been successfully submitted',
              body: `<p>The application has been submitted to one of our analysts to obtain additional information.</p>
          <p>After analyzing the information provided, a confirmation of the decision will be sent to the consumer by mail. The policy will take effect only when the consumer receives this confirmation.</p>`,
            },
            denied: {
              title: 'The application was declined',
              body: 'Due to some answers provided in the questionnaire, it is not possible to proceed with this application.',
              status: 'Status: ',
              outcomes: {
                [OutcomeCode.denied]: 'Denied',
                [OutcomeCode.blocked]: 'Blocked',
                [OutcomeCode.blockedAtQuoter]: 'Blocked at quoter',
              },
            },
          },
          downloadPdf: 'Download your application',
        },
        errorView: {
          title: 'An error occurred!',
          somethingWentWrong: `<p>Something went wrong when submitting the application.</p><p> Please, try again.</p>`,
        },
      },
      sendESignatureRequest: {
        body1:
          "By clicking 'Send to Signature', all signers will receive an email invitation to sign along with either an SMS validation code or the passcode you will provide to them directly.",
        body2: 'Did you validate all contact information?',
      },
      cancelESignature: {
        body1: "You're about to cancel the signature request. This action is irrevocable.",
        body2: 'If you proceed, stakeholders will not be able to access the documents for signature.',
      },
      trackESignature: {
        title: 'Keep track of the signature process',
        body: 'Get the status on signatures and underwriting at any time by clicking the Signatures button on the top right corner.',
        checkboxLabel: 'Do not show me this message again',
        confirmButton: 'Got it!',
      },
      fileAttachment: {
        deleteAttachment: 'Delete file attachment',
        errorUploadInvalidMinimumSize: '{{filename}} appears to be an empty file ({{size}})',
        errorUploadInvalidName: 'File name cannot contained special characters ({{specialChars}})',
        errorUploadLimitExceeded: '{{filename}} exceeds the maximum upload size ({{size}}) for the platform.',
        modalTitle: 'Files and Documents',
        openAttachment: 'Open file attachment',
        uploadButton: 'Upload a file to this application',
        uploadedOn: 'Uploaded on {{date}}',
        errorMaxFileSize: 'Maximum file size exceeded',
        fileNotFoundUploadAgain:
          'This file is no longer available due to the {{carrierName}} data retention policy. Please delete the file and re-upload it to continue.',
        fileDeletedByRetentionPolicy:
          'This file has been removed due to the {{carrierName}} data retention policy and is no longer available.',
      },
      addPaymentMethod: {
        creditCard: {
          modalTitle: 'Credit Card Information',
          informationText:
            'This credit card will be charged the first premium after successfully submitting the application. It will be then used for monthly recurring payment of subsequent premiums.',
        },
      },
      deleteProposedInsured: {
        title: 'Delete Insured',
        body1: 'Are you sure you want to delete <i>{{name}}</i>?',
        body2: 'All information associated with this insured will also be deleted.',
      },
      fieldCard: {
        fallbackTitle: 'Question {{id}}',
      },
    },
    promptCompleteProfile: {
      welcome: 'Welcome!',
      fullAccess: 'In order to use the platform please complete your profile.',
      submit: 'Complete my profile',
    },
    sendEmail: {
      topics: 'Topics',
      selectNeedsAnalysis: 'Select the Needs Analysis you want to share with your lead:',
      willBeSentTo: 'An invitation to complete a needs analysis will be sent to :',
      copyLink: 'Copy the Invitation Link to Clipboard',
      invalidEmail:
        'Are you sure this is the right email? This email was known to be invalid. If you are certain, click send again.',
      confirmSend: 'Are you sure this is the right email? If so, please confirm and click send.',
    },
    sharePublicLink: {
      title: 'Share my Public Link',
      copyLinkLabel: 'Copy your Public Link and share it with your network',
      copyPublicLink: 'Copy My Public Link',
      profilePage: 'Profile Page',
    },
    notificationPreferences: {
      title: 'Email Notification Preferences',
      body: 'Select the type of email notifications you want to receive:',
      emails: {
        [EmailNotificationTypes.leadScheduledACallEmail]: 'A Lead requests to book a meeting',
        [EmailNotificationTypes.leadCompletedNeedsAnalysisEmail]: 'A Lead completes the Needs Analysis',
        [EmailNotificationTypes.signatureEmailBouncedErrorEmail]:
          'One of the signers in an application has an email bounce',
        [EmailNotificationTypes.signatureCreatePackageErrorEmail]:
          'A signature ceremony failed to start for an application',
        [EmailNotificationTypes.applicationSubmittedEmail]:
          'All signers have signed an application, and it has been submitted successfully',
      },
    },
    editUser: {
      title: 'My profile',
      contactInfo: 'Contact Information',
      uploadProfilePicture: 'Upload a photo',
      mySharingPreferences: 'My Sharing Preferences',
      selectDefaultPreference: 'Please select the default subject of your shared links and email invitations:',
      clickToCopy: 'Click the button to copy the link:',
      copyLinkToClipboard: 'Copy link to clipboard',
      openInNewTab: 'Open link in a new tab',
      platformLanguage: 'Platform Language',
      selectAPlatformLanguage: 'Please select your desired display language',
      selectASharingPreference:
        'Select a subject above and save your profile to access your “Copy link to clipboard” button',
      saveSharingPreferences: 'Save your profile in order to keep these preferences as default.',
      agencyName: 'Agency Name',
      revertAgencyName: 'Revert to default name',
      securityDisclosureToolTip:
        'Please enter your securities disclosure (if applicable) and submit it through the standard advertising review process.',
    },
    copyApplication: {
      copyApplicationChooseContent:
        'Please choose the content from application #{{refNoIncrement}} that will be copied to a new application.',
      insured: 'Insured information',
      owner: 'Owner information',
      copyApplicationMissingSectionsError: 'Must select at least one section',
    },
    deleteLead: {
      deleteLeadTitle: 'Delete Lead',
      deleteApplicationTitle: 'Delete Application',
      deleteLeadConfirmation: 'Are you sure you want to delete this lead?',
      deleteApplicationConfirmation: 'Are you sure you want to delete this application?',
      irreversible: 'This action is irreversible.',
    },
    userDetails: {
      title: 'Create a user',
      selectASharingPreferenceLabel:
        "Select the user's default subject for his shared links and email invitations (optional):",
    },
    confirmDeleteUser: { title: 'Delete User', confirmDelete: 'Are you sure you want to delete the following user?' },
    confirmDeleteFile: {
      title: 'Delete file',
      optionalFile: {
        body: 'Are you sure you want to delete this file?',
      },
      requiredFile: {
        body1: 'If you delete a required document you will have to upload it again to complete this application.',
        body2: 'Are you sure you want to delete the file that was uploaded?',
      },
    },
    confirmResetApplicationStatus: {
      title: 'Reset application status',
      body1: 'You are currently in the process of resetting an application that is in submitted state.',
      body2:
        "Please note that application: '{{refNo}}' with the UUID '{{applicationId}}' will be reset and will require action from the advisor to re-submit the application in the Hybrid Origination platform.",
      resetButton: 'Reset Application',
    },
    manualSubmission: {
      title: 'Manual Submission',
      submitButton: 'Submit and Download',
      closeButton: 'Close',
      explanation:
        'You will download a zip files with everything that is available on that application. We expect you to send the files to the carrier. Right after the download, the application will be set to "manually submitted" in the system.',
      error: 'Something went wrong with the manual submission, contact the support for assistance.',
    },
    sendUserInvitationModal: {
      title: 'Send a reminder',
      willBeSentTo: 'A reminder to login to the platform will be sent to :',
    },
    leadsAssign: {
      assignSingleLeadTitle: 'Assign this lead to',
      assignMultipleLeadsTitle: 'Assign these {{numberOfLeads}} leads to',
      assignSingleApplicationTitle: 'Assign this application to',
      assignMultipleApplicationsTitle: 'Assign these {{numberOfLeads}} applications to',
      unassignedTitle: 'Unassigned',
      unassignedLeadCardSubtitle: 'This lead will not be assigned to anyone',
      unassignedApplicationCardSubtitle: 'This application will not be assigned to anyone',
      assignToMe: 'Assign to me',
    },
    leadsListFilters: {
      filterLeadsTitle: 'Filter leads by advisor',
      filterApplicationsTitle: 'Filter applications by advisor',
      submit: 'Submit',
      me: 'Me',
      all: 'All Users',
      unassigned: 'Unassigned',
      showAllLeads: 'Show all leads',
      showAllApplications: 'Show all applications',
      showUnassignedLeads: 'Show leads that are unassigned',
      showUnassignedApplications: 'Show applications that are unassigned',
      showMyLeads: 'Show leads that are assigned to you',
      showMyApplications: 'Show applications that are assigned to you',
    },
    leadsArchive: {
      leadNotAssignedToYou: 'This lead is not assigned to you.',
      applicationNotAssignedToYou: 'This application is not assigned to you.',
      archiveConfirmationQuestion: 'Are you sure you want to archive it?',
      unarchiveConfirmationQuestion: 'Are you sure you want to unarchive it?',
      archiveLeadTitle: 'Archive Lead',
      archiveApplicationTitle: 'Archive Application',
      unarchiveLeadTitle: 'Unarchive Lead',
      unarchiveApplicationTitle: 'Unarchive Application',
    },
    filesDownload: {
      title: 'Files and Documents',
    },
    importUsers: {
      importUsers: 'Import Users',
      import: 'Import',
      success: 'Import job was successfully completed. Here is the summary: ',
      invalidFileLength: 'The target file must contain at least one user to import.',
      invalidFileHeader: 'Invalid or missing header in the imported file.',
      maxUsersExceeded: 'A maximum of {{maxNumberOfUsers}} users can be imported at a time',
      invalidNumberOfColumn:
        'Unable to parse the user. {{nbOfColumnRequired}} columns should be specified per row and only {{nbOfColumnSpecified}} were specified. Row: [{{data}}].',
      invalidFileFormat: 'The file format is not supported. Supported formats: {{extensions}}',
      fileParsingError: 'An error occurred while importing the file. Ensure that it is properly formatted.',
      generalError: 'An unexpected error occurred while importing users.',
    },
    agentLicenses: {
      title: 'Licenses',
      provinceOrState: 'Province / State',
      status: 'License Status',
      expirationDate: 'Expiration Date',
      type: 'Type',
      active: 'Active',
      expired: 'Expired',
    },
    extractDataConsent: {
      title: 'Extract all lead data',
      body: 'Please note that the Zinnia Hybrid Origination platform is not storing the data extract file that you are requesting. Once the file has been transferred successfully to your assigned sFTP server, the file will be deleted from our database. By clicking the Acknowledge button, you are accepting that Zinnia is not responsible to retain a file each time this data extract feature is executed.',
      submitButton: 'Acknowledge',
    },
    submitPaperApp: {
      title: 'Submission',
      body: 'Application submission',
      submitButton: 'Submit',
      submitSuccessMessage: 'Application was successfully submitted',
      submitErrorMessage: 'An error occurred',
    },
    createButton: {
      title: 'Create New Application',
      formType: 'Form Type',
      formTypeLabel: 'Select the relevant form for your application',
      lineOfBusiness: 'Line of Business',
      lineOfBusinessLabel: 'Select a line of business for your application',
      lineOfBusinessChoices: {
        [LineOfBusinessName.life]: 'Life',
        [LineOfBusinessName.annuity]: 'Annuity',
        [LineOfBusinessName.legacyLife]: 'Life',
        [LineOfBusinessName.legacyAnnuity]: 'Annuity',
      },
      applicationMode: 'Application Mode',
      applicationModeLabel: 'Select an application mode',
    },
    confirmPricingFieldIdentifiers: {
      title: 'Confirm pricing field identifiers changes',
      body: 'Are you sure you want to save the pricing field identifiers changes?',
    },
  },
  tabs: {
    active: {
      empty: {
        hello: 'Hello {{name}}',
        noApplication: 'You have no application at this time.',
        startBy: 'Start by building a new lead',
      },
      searchEmpty: 'There are no active leads matching your search',
    },
    archived: {
      empty: {
        hello: 'Hello {{name}}',
        thereAreNoLeads: 'There are currently no archived leads',
      },
      searchEmpty: 'There are no archived leads matching your search',
    },
    needsAnalysis: {
      sent: 'Needs Analysis Sent',
      details: 'Details',
      back: 'Back',
      notSent: {
        notSentYet: 'No Needs Analysis has been sent to this client yet.',
        wantToSendItNow: 'Want to send it now?',
      },
      pending: {
        pendingInfo: 'Pending information',
        hasBeenSent: 'An invitation has already been sent',
      },
    },
  },
  logout: {
    text: 'You are now logged out of the platform.',
  },
  product: {
    roundedAmount: 'Amount must be rounded to the nearest {{amount}}',
    missingNeedsAnalysisInformation:
      "We're missing information to determine eligible products and calculate prices. Please invite your Customer to do a Needs Analysis.",
  },
  sidebar: {
    customers: 'Customers',
    applications: 'Applications',
    admin: 'Admin',
    logo: 'Logo',
  },
  admin: {
    importData: {
      tooltip: 'Import',
      blueprintsAndSalesDecisionRules: 'Import Questionnaire and Sales Decision Rules file',
      insuranceProductsAndFirms: 'Import Product and Firm lists',
      theme: 'Import Theme file',
      userGroupTheme: 'Import Theme file for {{userGroup}}',
      settings: 'Import Settings file',
    },
    exportData: {
      tooltip: 'Export',
    },
    themeManagement: {
      title: 'Theme',
      subtitle: "Personalize the Breathe Life platform's theme to match your branding.",
      colors: {
        title: 'Colors',
        primary: 'Primary',
        secondary: 'Secondary',
        tertiary: 'Tertiary',
      },
      logos: {
        title: 'Logos',
        compactButtonText: 'Modify compact logo',
        regularButtonText: 'Modify regular logo',
      },
      navigationSidebarVariant: {
        title: 'Navigation Sidebar Variant',
        options: {
          [NavigationSidebarVariants.primary]: 'Primary',
          [NavigationSidebarVariants.secondary]: 'Secondary',
          [NavigationSidebarVariants.light]: 'Light',
        },
      },
      themeProfile: 'Theme profile',
      themeDefault: 'Default theme',
    },
    settingsManagement: {
      title: 'Settings',
      subtitle: 'Define settings to be used by the Breathe Life platform',
      languages: {
        title: 'Language Settings',
        enabledLanguagesLabel: 'Select the enabled languages',
        defaultLanguageLabel: 'Select the default language',
        enabledLanguagesError: 'Must select at least one enabled language',
        defaultLanguageError: 'Default language must be part of enabled languages',
        options: {
          [Language.en]: 'English',
          [Language.fr]: 'French',
        },
      },
      carrierNames: {
        title: 'Company Name Settings',
        carrierNameEmpty: 'At least one company name must be provided.',
        label: 'Enter company name  - {{language}}',
        placeholder: 'Company Name - {{language}}',
      },
      publicLink: {
        title: 'Public Link Settings',
        enablePublicLink: 'Enable Public Link',
        titleLabel: 'Title - {{language}}',
        descriptionLabel: 'Description - {{language}}',
        imageLabel: 'Image - {{language}}',
        imageButtonText: 'Modify public link image - {{language}}',
      },
      consumerFooter: {
        title: 'Consumer Footer Settings',
        subtitle: 'Current year can be displayed by adding a {year} placeholder in the text',
        label: 'Enter footer text - {{language}}',
      },
    },
    questionnaireManagement: {
      title: 'Questionnaire',
      version: 'Version {{majorVersion}}.{{minorVersion}} - {{description}}',
      emptyDescription: 'No description',
      nodeIds: 'Field answer identifiers',
      questionnaireEditorData: 'questionnaire editor data',
      questionnaireListData: 'questionnaire list data',
      draftQuestionnaireVersion: 'Draft',
      autoSaving: 'Saving...',
      publish: 'Publish',
      createDraft: 'Create Draft',
      newDraft: 'New Draft',
      open: 'Open',
      preview: 'Preview',
      previewRequirements: {
        [QuestionnairePreview.consumerFlow]: 'Need to configure at least one product and select one preview type.',
        [QuestionnairePreview.assistedApplication]:
          'Need to configure at least one product and select one preview type.',
        [QuestionnairePreview.pdf]:
          'Need to submit an application in the consumer flow OR assisted application previews',
      },
      previewDescription:
        'Please select the display type for the Preview. The preview will appear in a new browser tab.',
      previewOptions: {
        consumerFlow: 'Consumer Flow',
        assistedApplication: 'Assisted Application',
        pdf: 'PDF',
      },
      return: 'Return to the questionnaire list',
      advancedOptions: {
        section: 'Advanced Section Options',
        subsection: 'Advanced Subsection Options',
        question: 'Advanced Field Group Options',
        field: 'Advanced Field Options',
      },
      dynamicOptions: {
        removeModal: {
          title: 'Remove dynamic options',
          content:
            'Do you really want to remove the dynamic options? The selected node ids and the configured conditions will be lost.',
          confirmButton: 'Remove dynamic options',
        },
        title: 'Dynamic Options',
        pickCollection: 'Pick a collection node id.',
        pickSelect: 'Form a label with node ids from that collection.',
        note: 'Note: The dynamics options are always added after the static options.',
      },
      jsonEditor: {
        title: 'Edit Questionnaire JSON',
        tooltip: 'Edit JSON',
        blueprintEditorTitle: 'Questionnaire blueprint',
        partsViewerTitle: 'Questionnaire parts',
      },
      description: 'Description',
      publishQuestionnaire: {
        modalTitle: 'Publish Questionnaire',
        modalDetails:
          'Publishing a questionnaire will make all <strong>new</strong> applications to use this version of the questionnaire. Applications which have already been started will continue to use older versions.',
      },
      createDraftQuestionnaire: {
        modalTitle: 'Create Draft Questionnaire',
        modalDetails: `Copies the current questionnaire into a new draft version. The published version will not be changed.`,
      },
      missingTitle: {
        section: 'Untitled Section',
        subsection: 'Untitled Subsection',
        question: 'Untitled Field Group',
        field: 'Untitled Field',
      },
      confirmDeleteModal: {
        title: 'Confirm delete',
        defaultContentWithoutResourceName: 'Are you sure you would like to delete this?',
        defaultContent: 'Are you sure you want to delete this {{resourceName}}?',
        section: 'section',
        subsection: 'subsection',
        selectOption: 'option',
        fieldGroup: 'field group',
        field: 'field',
      },
      createQuestionnaireModal: {
        title: 'Create a new Questionnaire',
        nameLanguageLabel: 'Title ({{language}})',
        lineOfBusinessLabel: 'Line of Business',
        submitLabel: 'Submit',
      },
      input: {
        sectionGroupTitle: 'Section Group Title ({{language}})',
        sectionTitle: 'Section Title',
        sectionIcon: 'Section Icon',
        subsectionTitle: 'Subsection Title',
        subsectionText: 'Subsection Text',
        subsectionIcon: 'Subsection Icon',
        subsectionNextStepButtonText: 'Subsection Next Step Button Text',
        questionTitle: 'Field Group Title',
        questionText: 'Field Group Text',
        fieldTitle: 'Field Title',
        fieldText: 'Field Text',
        fieldPlaceholder: 'Field Placeholder',
        fieldLabel: 'Field Label',
        fieldType: 'Field Type',
        infoFieldStyling: 'Information Field Styling',
        dataLabel: 'Data Label',
        subsectionVariant: 'Subsection Variant',
        requiredField: 'Required Field',
        disabledField: 'Disabled Field',
        validationType: 'Validation',
        answerStorageNodeId: 'Field Answer',
        referenceLabel: 'Reference Label',
        repeatableQuestionNodeId: 'Repeated Collection Answers',
        options: {
          title: 'Options',
          [0]: 'Drag and drop options in this box to appear first in the dropdown',
          [1]: '',
          [2]: 'Drag and drop options in this box to appear last in the dropdown',
          source: {
            title: 'Source',
            dataTypeAsterisk: '*Only works when the data is within an array',
            tagPlaceholder: 'ex: salaryDeduction',
            labelKeyPlaceholder: 'ex: name, path.to.name, path.to.name[0].en',
            valuePathPlaceholder: 'ex: id, path.to.id',
            modal: {
              title: 'Toogle Options Source',
              content: 'Changing dropdown options data source will reset existing options. Do you want to continue?',
              buttonConfirm: 'Yes',
            },
            label: {
              blueprint: 'Blueprint',
              applicationContext: 'Application Context',
              tag: 'Tag',
              labelKey: 'Label Key',
              valuePath: 'Value Path',
            },
          },
        },
        consentModalHeader: 'Consent Modal Header',
        consentText: 'Consent Text',
        agreeFieldTextTooltip:
          'This property sets text for the button which opens the assisted application agree modal',
        agreeFieldTitleTooltip:
          'This property sets the text for the modal confirmation button in the assisted application, as well as the text beside the agree checkbox in the consumer flow',
        buttonText: 'Button text',
        buttonTextAndLanguage: 'Button text ({{language}})',
        consentTextTooltip:
          'This property sets the consent text that will be displayed in the agree modal of the assisted application; it will also appear above the agree checkbox in the consumer flow',
        consentModalHeaderTooltip: 'This property is the header of the modal displayed in the assisted application',
        consentModalHeaderAndLanguage: 'Consent Modal Header ({{language}})',
        consentTextAndLanguage: 'Consent Text ({{language}})',
        autocompleteCountryCode: 'Address Autocomplete Country Code',
        autocompleteCountryCodes: {
          US: 'United States',
          CA: 'Canada',
        },
        autocompleteFieldIsTheStreetAddressField: 'This field is the street address',
        autocompleteNodeId: 'Address Autocomplete Node Id',
        autocompleteStreetAddressField: 'Street Address Field',
        autocompleteCityField: 'City Field',
        autocompleteStateOrProvinceField: 'State or Province Field',
        autocompletePostalCodeOrZipField: 'Postal Code or Zip Field',
        addOption: 'Add Option',
        addField: 'Add Field',
        addQuestion: 'Add Field Group',
        addSubsection: 'Add Subsection',
        addSection: 'Add Section',
        textAndLanguage: 'Text ({{language}})',
        titleAndLanguage: 'Title ({{language}})',
        placeholderAndLanguage: 'Placeholder ({{language}})',
        addButtonTextAndLanguage: 'Add Button Text ({{language}})',
        removeButtonTextAndLanguage: 'Remove Button Text ({{language}})',
        minRepeatable: 'Minimum Repetitions',
        maxRepeatable: 'Maximum Repetitions',
        iconName: 'Icon Name',
        fieldSize: 'Field Size',
        forceNewLine: 'Force new line',
        validationMessageAndLanguage: 'Validation Error Message ({{language}})',
        createOption: {
          title: 'Create Option',
          optionId: 'Option Id',
        },
        createField: {
          title: 'Create Field',
        },
        createQuestion: {
          title: 'Create Field Group',
        },
        updateQuestion: {
          title: 'Update Field Group',
        },
        createSubsection: {
          title: 'Create Subsection',
        },
        createSection: {
          title: 'Create {{sectionGroupTitle}} Section',
        },
        renameOption: {
          title: 'Rename Option Id',
          optionId: 'Option Id',
        },
        noneSet: 'None set',
        requiredIfAnySet: 'Required if any other value is set',
        productFieldAnswers: 'Product Field Answers',
        coverageAmountFieldAnswer: 'Coverage Amount Field Answer',
        numericalDataType: 'Numerical Data Type',
        premiumFieldAnswer: 'Premium Field Answer',
        displayAsCard: 'Display as card in the assisted application',
        displayInCardPreview: 'Display in card preview in the assisted application',
        showInNavigation: 'Show in the navigation menu of the assisted application',
        pageBreakSubSectionInPdf: 'Place this SubSection on a new page in the PDF',
        checked: 'Checked',
        unchecked: 'Unchecked',
        noDefault: 'No Default Value',
        defaultValue: 'Default Value',
        numberOfInsured: 'Number of Insured',
        participantRole: 'Participant Role',
        searchable: 'Searchable',
      },
      editSectionGroup: 'Edit Section Group',
      editSection: 'Edit Section',
      editSubsection: 'Edit Subsection',
      editQuestion: 'Edit Field Group',
      editField: 'Edit Field',
      editFieldHeading: '{{fieldType}} Field',
      editInfoSupplement: 'Edit Tooltip',
      hideSection: 'Collapse',
      remove: 'Remove',
      show: 'Show',
      hide: 'Hide',
      hiddenFields: 'Hidden fields:',
      hiddenFieldGroups: 'Hidden field groups:',
      infoSupplement: 'Tooltip',
      repeatable: 'Repeatable',
      importFromCopyDeck: 'Import from Copy Deck',
      importFromCopyDeckSuccess: 'Successfully Imported from Copy Deck',
      importFromCopyDeckListErrors: 'List of import Errors',
      clipboard: {
        copySection: 'Copy section',
        copySubsection: 'Copy subsection',
        copyFieldGroup: 'Copy field group',
        copyField: 'Copy field',
        pasteSection: 'Paste section',
        pasteSubsection: 'Paste subsection',
        pasteFieldGroup: 'Paste field group',
        pasteField: 'Paste field',
      },
      search: {
        results: {
          singular: 'Result',
          plural: 'Results',
        },
      },
      rules: {
        visibility: {
          edit: 'Edit Visibility Conditions',
          conditions: 'Visibility Conditions',
        },
        validation: {
          addCondition: 'Add Validation Condition',
          edit: 'Edit Validation Conditions',
          conditions: 'Validation Conditions',
          messagePrompt: 'Failure Message: {{message}}',
        },
        addCondition: 'Add Condition',
        removeCondition: 'Remove Condition',
      },
      fieldTypes: {
        input: 'Text',
        radio: 'Radio',
        dropdown: 'Dropdown',
        checkboxGroup: 'Checkbox Group',
        number: 'Number',
        money: 'Money',
        date: 'Date',
        phone: 'Phone',
        checkbox: 'Checkbox',
        textarea: 'Text Area',
        information: 'Information',
        button: 'Button',
        currencyCard: 'Currency Card',
        autocomplete: 'Address Autocomplete',
        yearMonth: 'Date (YYYY-MM)',
        agree: 'Agree',
        signature: 'Electronic Signature',
      },
      infoFieldStyles: {
        info: 'Info',
        paragraph: 'Paragraph',
        success: 'Success',
        warning: 'Warning',
        error: 'Error',
      },
      subsectionVariants: {
        [SubsectionVariant.summary]: 'Summary',
        [SubsectionVariant.quoter]: 'Quoter',
        simpleQuoter: 'Simple Quoter',
        [SubsectionVariant.payment]: 'Payment',
        [SubsectionVariant.submission]: 'Submission',
        hideHeader: 'Hide Header',
        displayInline: 'Display Inline',
        variantWarning:
          'This feature if not supported by all carriers. If you are unsure, consult with the engineering team before using it.',
        [SubsectionVariant.addon]: 'Addon',
        [SubsectionVariant.productSummary]: 'Product Summary',
        [SubsectionVariant.productSelection]: 'Product Selection',
      },
      dataLabels: {
        inherit: 'Inherit from parent',
        Unknown: 'Unknown',
        KnownSafe: 'Known Safe',
        PII: 'PII',
        PHI: 'PHI',
        IdentifiablePHI: 'Identifiable PHI',
        ReferenceNumber: 'Reference Number',
        PostalCode: 'Postal Code',
        DateOfBirth: 'Date Of Birth',
        IdentifiableDate: 'Identifiable Date',
        NonIdentifiableDate: 'Non-Identifiable Date',
        FreeFormText: 'Free Form Text',
        AgeAtServiceTime: 'Age At Service Time',
        Language: 'Language',
        AdvisorEmail: 'Advisor Email',
        LoginEmail: 'Login Email',
        InsuredPersonEmail: 'Proposed Insured Email',
        LeadEmail: 'Lead Email',
        Email: 'Email',
      },
      pdfDocuments: {
        title: 'PDF Document(s)',
        [PdfDocumentType.application]: 'Application PDF',
        [PdfDocumentType.advisorsReport]: 'Advisor PDF',
        [PdfDocumentType.carrierReport]: 'Carrier PDF',
        [PdfDocumentType.external]: 'External',
        [PdfDocumentType.oneSpanEvidenceSummary]: 'OneSpan Evidence Summary',
      },
      rendering: {
        title: 'Render On:',
        [QuestionnaireBlueprintRenderOn.web]: 'Web',
        [QuestionnaireBlueprintRenderOn.pdf]: 'PDF',
        [QuestionnaireBlueprintRenderOn.summary]: 'Summary Page',
      },
      platforms: {
        title: 'Flow:',
        [PlatformType.producer]: 'Producer / Advisor',
        [PlatformType.consumer]: 'Consumer',
      },
      module: {
        title: 'Belongs To:',
        [InsuranceModule.insuranceApplication]: 'Application',
        [InsuranceModule.needsAnalysis]: 'Needs Analysis',
      },
      applicationMode: {
        title: 'Type of Application:',
        paper: 'Paper',
        digital: 'Digital',
      },
      copyable: {
        title: 'Copyable To:',
        none: 'None',
        insured: 'Insured',
        owner: 'Owner',
      },
      validateAs: {
        string: 'None',
        date: 'None',
        boolean: 'None',
        booleanTrue: 'Must accept terms',
        integer: 'Integer',
        decimal: 'Decimal',
        percentage: 'Percentage',
        yearMonth: 'YYYY-MM',
        yearMonthPastDate: 'YYYY-MM (Past Date)',
        yearMonthPastOrCurrentDate: 'YYYY-MM (Past Or Current Date)',
        yearMonthFutureDate: 'YYYY-MM (Future Date)',
        yearMonthFutureOrCurrentDate: 'YYYY-MM (Future Or Current Date)',
        futureDate: 'Future Date',
        futureOrCurrentDate: 'Future Or Current Date',
        pastDate: 'Past Date',
        pastOrCurrentDate: 'Past Or Current Date',
        currentDate: 'Current Date',
        email: 'Email',
        sin: 'SIN',
        ssn: 'SSN',
        canadianPostalCode: 'Postal Code',
        zipCode: 'Zip Code',
        phone: 'Phone',
        branchNumber: 'Branch Number',
        institutionNumber: 'Institution Number',
        accountNumber: 'Account Number',
        firstName: 'First Name',
        lastName: 'Last Name',
        middleName: 'Middle Name',
        preferredName: 'Preferred Name',
        signature: 'Electronic Signature',
      },
      triggerStepNavigation: {
        label: 'Navigates to next step',
        description:
          'When enabled, indicates that a button click will try to go to the next step if the current form is valid.',
      },
      fieldWidthOptions: {
        [OptionSize.full]: 'Full',
        [OptionSize.half]: 'Half',
        [OptionSize.oneThird]: 'One Third',
      },
      fieldSizes: {
        [FieldSizes.full]: 'Full',
        [FieldSizes.threeQuarters]: 'Three Quarters',
        [FieldSizes.twoThirds]: 'Two Thirds',
        [FieldSizes.half]: 'Half',
        [FieldSizes.third]: 'One Third',
        [FieldSizes.quarter]: 'One Quarter',
      },
      table: {
        description: 'Description',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        version: 'Version',
        applicationsCount: 'Applications Associated',
        status: 'Status',
        draft: 'Draft',
        published: 'Published',
        noQuestionnairesFoundTitle: 'No questionnaires found',
        noQuestionnairesFoundDetails: 'The questionnaire list will be displayed once a questionnaire is created',
        applicationSchema: 'Application schema',
        questionnaireSchema: 'Questionnaire schema',
      },
      questionnaireIconLabels: {
        [IconName.error404]: 'Error 404',
        [IconName.advisor]: 'Advisor',
        [IconName.afterDeath]: 'After Death',
        [IconName.alcohol]: 'Alcohol',
        [IconName.averageFinancialHealth]: 'Average Financial Health',
        [IconName.bullet]: 'Bullet',
        [IconName.calculatorCoverage]: 'Calculator Coverage',
        [IconName.car]: 'Car',
        [IconName.check]: 'Check',
        [IconName.checklist]: 'Checklist',
        [IconName.checkmark]: 'Checkmark',
        [IconName.close]: 'Close',
        [IconName.confirmation]: 'Confirmation',
        [IconName.confirmationOutlined]: 'Confirmation Outlined',
        [IconName.contact]: 'Contact',
        [IconName.coverage]: 'Coverage',
        [IconName.day]: 'Day',
        [IconName.death]: 'Death',
        [IconName.debtsSavings]: 'Debts & Savings',
        [IconName.drugs]: 'Drugs',
        [IconName.edit]: 'Edit',
        [IconName.education]: 'Education',
        [IconName.educationCoverageCalculator]: 'Education Coverage Calculator',
        [IconName.excellentHealth]: 'Excellent Health',
        [IconName.excellentFinancialHealth]: 'Excellent Financial Health',
        [IconName.extremeSports]: 'Extreme Sports',
        [IconName.family]: 'Family',
        [IconName.familyIncome]: 'Family Income',
        [IconName.file]: 'File',
        [IconName.fileDownload]: 'File Download',
        [IconName.future]: 'Future',
        [IconName.goodHealth]: 'Good Health',
        [IconName.goodFinancialHealth]: 'Good Financial Health',
        [IconName.greatHealth]: 'Great Health',
        [IconName.handshake]: 'Handshake',
        [IconName.health]: 'Health',
        [IconName.hospital]: 'Hospital',
        [IconName.loading]: 'Loading',
        [IconName.loadingCalculator]: 'Loading Calculator',
        [IconName.mail]: 'Mail',
        [IconName.marijuana]: 'Marijuana',
        [IconName.mib]: 'Medical Information Bureau (MIB)',
        [IconName.morning]: 'Morning',
        [IconName.night]: 'Night',
        [IconName.payment]: 'Payment',
        [IconName.personalInformation]: 'Personal Information',
        [IconName.phone]: 'Phone',
        [IconName.poorFinancialHealth]: 'Poor Financial Health',
        [IconName.poorHealth]: 'Poor Health',
        [IconName.police]: 'Police',
        [IconName.plane]: 'Plane',
        [IconName.preference]: 'Preference',
        [IconName.smoking]: 'Smoking',
        [IconName.summary]: 'Summary',
        [IconName.unfortunately]: 'Unforturnately',
        [IconName.update]: 'Update',
        [IconName.watch]: 'Watch',
        [IconName.weight]: 'Weight',
        [IconName.work]: 'Work',
        [IconName.wait]: 'Wait',
        [IconName.close2]: 'Close 2',
        [IconName.success]: 'Success',
        [IconName.successOutlined]: 'Success Outlined',
        [IconName.warning]: 'Warning',
        [IconName.warningOutlined]: 'Warning Outlined',
        [IconName.information]: 'Information',
        [IconName.informationOutlined]: 'Information Outlined',
        [IconName.error]: 'Error',
        [IconName.errorOutlined]: 'Error Outlined',
        [IconName.triangle]: 'Triangle',
        [IconName.dragHandle]: 'Drag Handle',
        [IconName.folder]: 'Folder',
        [IconName.fileMenu]: 'File Menu',
        [IconName.list]: 'List',
        [IconName.question]: 'Question',
        [IconName.threeDots]: 'Ellipsis',
        [IconName.search]: 'Search',
      },
      numericalDataTypeOptions: {
        [NumericalDataType.float]: 'Float',
        [NumericalDataType.integer]: 'Integer',
      },
      deleteQuestionnaire: {
        success: 'The questionnaire was deleted',
        error: 'The questionnaire could not be deleted',
        deleteQuestionnaire: 'Delete questionnaire',
        confirmation: 'Are you sure you want to delete this questionnaire?',
      },
    },
    pricingFieldIdentifiersManagement: {
      title: 'Pricing Field Identifiers',
      questionnaireVersion: 'Questionnaire version',
    },
    firmManagement: {
      noFirmsFoundTitle: 'No firms found',
      noFirmsFoundText: 'The firm list will be displayed once an insurance firm is created',
      labels: {
        name: 'Name',
        createdAt: 'Created At',
        productsCount: 'Products associated',
        nameAndLanguage: 'Name ({{language}})',
        addressAndLanguage: 'Address ({{language}})',
        uploadLogoButtonText: 'Add insurance firm logo - {{language}}',
        changeLogoButtonText: 'Change insurance firm logo - {{language}}',
        primaryPhoneNumber: 'Primary phone number',
        tollFreePhoneNumber: 'Toll-free phone number',
        faxNumber: 'Fax number',
        websiteUrl: 'Website URL',
        email: 'Email',
        firmAmfNumber: 'Firm AMF number',
        insurerAmfNumber: 'Insurer AMF number',
        privacyPolicyUrl: 'Privacy policy URL',
        firmRegistrationUrl: 'Firm registration URL',
        insurerRegistrationUrl: 'Insurer registration URL',
      },
      insuranceFirm: {
        singular: 'insurance firm',
        plural: 'insurance firms',
      },
      createNewFirm: 'Create new insurance firm',
    },
    featuresManagement: {
      warning: {
        title: 'Not available',
        subtitle: 'The features management tools are under development',
      },
    },
    adHocSignatureRequestsManagement: {
      table: {
        createdAt: 'Created date',
        context: 'Context',
        applicationId: 'Application ID',
        refNo: 'Reference number',
        status: 'Status',
        description: 'Description',
      },
      requestStatuses: {
        [ESignAdhocStatus.Cancelled]: 'Cancelled',
        [ESignAdhocStatus.Completed]: 'Completed',
        [ESignAdhocStatus.Error]: 'Error',
        [ESignAdhocStatus.InProgress]: 'In progress',
        [ESignAdhocStatus.NotStarted]: 'Not started',
      },
      signatureStatuses: {
        [ESignAdhocSignerStatus.Bounced]: 'Bounced',
        [ESignAdhocSignerStatus.Error]: 'Error',
        [ESignAdhocSignerStatus.NotSigned]: 'Not Signed',
        [ESignAdhocSignerStatus.Signed]: 'Signed',
      },
      return: 'Return to the ad-hoc signature requests list',
      documentsTableColumns: {
        signerName: 'Signer name',
        signerRole: 'Role',
        signerEmail: 'Email',
        status: 'Status',
      },
      eventsTableColumns: {
        data: 'Data',
        createdBy: 'Created by',
      },
      transactionsTableColumns: {
        providerName: 'Provider name',
        transactionId: 'Transaction ID',
        createdAt: 'Created date',
      },
      buttons: {
        restartSignatureRequest: 'Restart request',
        startSignatureRequest: 'Start request',
        cancelSignatureRequest: 'Cancel request',
      },
      sectionTitles: {
        requestDetails: 'Ad-hoc Signature Request Details',
        context: 'Context',
        applicationId: 'Application ID',
        refNo: 'Reference number',
        createdAt: 'Created date',
        status: 'Status',
        documents: 'Documents',
        events: 'Events',
        transactions: 'Transactions',
      },
    },
    documentDetailManagement: {
      title: 'Document',
      documentRule: {
        singular: 'document rule',
        plural: 'document rules',
        currentlyEditingVersion: 'For questionnaire version: {{version}}',
      },
      documentFieldEditorDialog: {
        newTitle: 'New Field',
        editTitle: 'Edit Field',
        fields: {
          placementType: 'Placement Type',
          fieldBinding: 'Field Binding (Node Id)',
          type: 'Type',
          anchorText: 'Anchor Text',
          anchorPoint: 'Anchor Point',
          anchorIndex: 'Index',
          anchorCharacterIndex: 'Character Index',
          top: 'Top',
          left: 'Left',
          topOffset: 'Top Offset',
          leftOffset: 'Left Offset',
          width: 'Width',
          height: 'Height',
          page: 'Page',
        },
      },

      addSigner: 'Add Signer',
      addRecipient: 'Add Recipient',
      recipient: 'Recipient',
      addSignatureField: 'Add Signature Field',
    },

    documentManagement: {
      noDocumentsFoundTitle: 'No documents found',
      noDocumentsFoundText: 'The document list will be displayed once a document is created',
      newDocumentDialog: {
        title: 'New Document',
      },
      archiveDocumentDialog: {
        title: 'Archive Document',
        message: 'Are you sure you want to archive this document?',
      },
      documentStatus: 'Document status',
      archiveStatusSelect: {
        active: 'Active',
        archived: 'Archived',
      },
    },
    productManagement: {
      insuranceProduct: { singular: 'insurance product', plural: 'insurance products' },
      insuranceProductRates: 'insurance product rates',
      noProductsFoundTitle: 'No products found',
      noProductsFoundText: 'The product list will be displayed once a product is created',
      createNewProduct: 'New insurance product',
      modifyPricing: 'Modify pricing',
      productPricing: 'Modify the {{name}} product pricing',
      selectAFile: 'Select a file',
      selectedFile: 'Selected file: {{fileName}}',
      noFileSelected: 'No file selected',
      addedAt: 'Added at {{date}}',
      labels: {
        productDescriptor: 'Product descriptor',
        name: 'Displayed name',
        nameAndLanguage: 'Name ({{language}})',
        type: 'Type',
        lineOfBusiness: 'Line of business',
        minimumAge: 'Minimum age',
        maximumAge: 'Maximum age',
        productId: 'Product ID',
        insuranceFirmId: 'Insurance firm',
        holdingForm: 'Holding form',
        supportedBuyers: 'Supported buyers',
        policyDetailsUrl: 'Policy details URL',
        temporaryInsuranceUrl: 'Temporary insurance PDF URL',
        renewable: 'Renewable',
        maxRenewalAge: 'Maximum renewable age',
        convertible: 'Convertible',
        addonOrRider: 'Rider / Addon',
        linkToTheseProducts: 'Attached to these products',
        noProductAvailable: 'No product available',
        linkToTheseAddons: 'Attached to these riders/addons',
        noAddonAvailable: 'No riders/addons available',
        missingProductName: '[Product name is missing]',
        maxConversionAge: 'Maximum convertible age',
        requiresMedicalExam: 'Requires medical exam',
        policyFee: 'Policy fee',
        additionalFeatures: 'Additional features',
        additionalFeature: 'Additional feature #{{index}}',
        coverageAmountRanges: 'Coverage amount ranges',
        coverageAmountRange: 'Coverage amount range #{{index}}',
        minimumCoverageAmount: 'Minimum coverage amount',
        maximumCoverageAmount: 'Maximum coverage amount',
        minimumSalary: 'Minimum salary',
        pricing: 'Pricing',
        modalFactor: 'Modal factor',
        paymentFrequency: 'Payment frequency',
        selectors: 'Product selectors',
        index: 'Index',
        productFieldAnswers: 'Product field answer',
        coverageAmountFieldAnswer: 'Coverage amount field answer',
        premiumFieldAnswer: 'Premium field Answer',
      },
      archiving: {
        archive: 'Archive',
        archived: 'Archived',
        yes: 'Yes',
        no: 'No',
        unarchive: 'Unarchive',
        modal: {
          title: 'Confirmation',
          descriptionForArchive:
            'Archived products are no longer visible in quoter pages, and cannot be selected in applications. Are you sure you want to archive this product?',
          descriptionForUnarchive:
            'Unarchived products are visible in quoter pages, and can be selected in applications. Are you sure you want to unarchive this product?',
        },
      },
      options: {
        lineOfBusiness: {
          [LineOfBusinessName.annuity]: 'Annuity',
          [LineOfBusinessName.criticalIllness]: 'Critical illness',
          [LineOfBusinessName.disability]: 'Disability',
          [LineOfBusinessName.health]: 'Health',
          [LineOfBusinessName.longTermCare]: 'Long term care',
          [LineOfBusinessName.medicalSupplement]: 'Medical supplement',
          [LineOfBusinessName.legacyAnnuity]: 'Annuity',
          [LineOfBusinessName.legacyLife]: 'Life',
          [LineOfBusinessName.life]: 'Life',
          [LineOfBusinessName.accidentIllness]: 'Accidental Illness',
          [LineOfBusinessName.incomeProtection]: 'Income Protection',
        },
        holdingForm: {
          [HoldingForm.individual]: 'Individual',
          [HoldingForm.jointIndividual]: 'Joint',
        },
        type: {
          [ProductType.criticalIllness]: 'Critical Illness',
          [ProductType.termLife]: 'Term life',
          [ProductType.wholeLife]: 'Whole life',
        },
        supportedBuyers: {
          [SupportedBuyer.consumer]: 'Client',
          [SupportedBuyer.pro]: 'Producer',
        },
        paymentFrequency: {
          [PaymentFrequency.monthly]: 'Monthly',
          [PaymentFrequency.quarterly]: 'Quarterly',
          [PaymentFrequency.annually]: 'Annually',
        },
      },
    },
    salesDecisionRulesManagement: {
      salesDecisionRule: { singular: 'sales decision rule', plural: 'sales decision rules' },
      questionnaire: { singular: 'questionnaire', plural: 'questionnaires' },
      questionnaireVersion: 'Questionnaire version',
      table: {
        conditions: 'Conditions',
        createdAt: 'Created on',
        outcomeCode: 'Outcome',
        reason: 'Reason',
        identifier: 'Identifier',
        noRulesFoundTitle: 'No sales decision rules found',
        noRulesFoundText: 'The sales decision rules will be displayed once they are created',
      },
      createNewSalesDecisionRule: 'Create a new sales decision rule',
      editSalesDecisionRule: 'Edit the sales decision rule',
      labels: {
        outcome: 'Application outcome',
        reason: 'Reason',
        identifier: 'Rule Identifier',
      },
      options: {
        outcomes: {
          [OutcomeCode.referred]: 'Referred to underwriting',
          [OutcomeCode.denied]: 'Denied',
          [OutcomeCode.blocked]: 'Blocked',
          [OutcomeCode.blockedAtQuoter]: 'Blocked at quoter',
          [OutcomeCode.accepted]: 'Accepted',
          [OutcomeCode.conditional]: 'Conditionally accepted',
        },
      },
    },
    conditions: {
      labels: {
        conditionBlueprintType: 'Condition',
        targetNodeId: 'Field answer',
        targetNodeIdByNumber: 'Field #{{number}} answer',
        selectValue: 'Select value',
        selectedValues: {
          singular: 'Selected value',
          pluralish: 'Selected value(s)',
          noOptions: 'No options available',
        },
        outcome: 'Application outcome',
        reason: 'Reason',
        minAge: 'Minimum age',
        maxAge: 'Maximum age',
        ageUnit: 'Age unit',
        months: 'Months',
        years: 'Years',
        days: 'Days',
        roundingType: 'Rounding type',
        roundingTypeNone: 'None',
        roundingTypeClosestBirthday: 'Closest Birthday',
        roundingTypeNextBirthday: 'Next Birthday',
        roundingTypeLastBirthday: 'Last Birthday',
        minLength: 'Minimum characters',
        maxLength: 'Maximum characters',
        height: 'Height field',
        weight: 'Weight field',
        minBmi: 'Minimum BMI',
        maxBmi: 'Maximum BMI',
        controlValue: 'Value to compare with',
        equality: 'Equality condition',
        emptiness: 'Emptiness condition',
        numberComparison: 'Number comparison condition',
        matches: 'Matches condition',
        collectionOperator: 'Collection operator',
        mathConditionOperator: 'Mathematical operator',
        measurementSystem: 'Measurement system',
        dateUnit: 'Date unit',
        startDate: 'Start date field answer',
        endDate: 'End date field answer',
        addCondition: 'Add condition',
        addNestedCondition: 'Add nested condition',
        removeCondition: 'Remove condition',
        removeNestedCondition: 'Remove Nested Condition',
        logicalOperator: 'Logical operator',
        noUpperLimit: 'infinity',
        answer: 'Answer',
        comparisonValueType: 'Comparison value type',
        nodeId: 'Node ID',
        number: 'Number',
        string: 'Comparison Value(s)',
        percent: 'Percent (%)',
        selectedCollection: 'Collection: {{collectionNodeId}}',
        collectionCompareToCurrentItem: 'Compare to current item',
        conditionSummary: {
          [ConditionBlueprintType.ageRange]: {
            default:
              'If age in <strong>{{unit}}</strong> rounded by <strong>{{roundingType}}</strong> is between <strong>{{minAge}}</strong> and <strong>{{maxAge}}</strong>',
            [BlueprintCollectionOperator.every]:
              'If <strong>every</strong> field of <strong>{{fieldName}}</strong> have an age that is between <strong>{{minAge}}</strong> and <strong>{{maxAge}}</strong> <strong>{{unit}}</strong> rounded by <strong>{{roundingType}}</strong>',
            [BlueprintCollectionOperator.some]:
              'If <strong>some</strong> fields of <strong>{{fieldName}}</strong> have an age that is between <strong>{{minAge}}</strong> and <strong>{{maxAge}}</strong> <strong>{{unit}}</strong> rounded by <strong>{{roundingType}}</strong>',
            [BlueprintCollectionOperator.none]:
              'If <strong>no</strong> fields of <strong>{{fieldName}}</strong> have an age that is between <strong>{{minAge}}</strong> and <strong>{{maxAge}}</strong> <strong>{{unit}}</strong> rounded by <strong>{{roundingType}}</strong>',
          },
          [ConditionBlueprintType.bmiRange]: {
            default: 'If BMI value is between <strong>{{minBmi}}</strong> and <strong>{{maxBmi}}</strong>',
            [BlueprintCollectionOperator.every]:
              'If <strong>every</strong> BMI value calculated from <strong>{{heightField}}</strong> (height) and <strong>{{weightField}}</strong> (weight) is between <strong>{{minBmi}}</strong> and <strong>{{maxBmi}}</strong>',
            [BlueprintCollectionOperator.some]:
              'If <strong>some</strong> of the BMI values calculated from <strong>{{heightField}}</strong> (height) and <strong>{{weightField}}</strong> (weight) is between <strong>{{minBmi}}</strong> and <strong>{{maxBmi}}</strong>',
            [BlueprintCollectionOperator.none]:
              'If <strong>none</strong> of the BMI values calculated from <strong>{{heightField}}</strong> (height) and <strong>{{weightField}}</strong> (weight) is between <strong>{{minBmi}}</strong> and <strong>{{maxBmi}}</strong>',
          },
          [ConditionBlueprintType.characterCountInBetween]: {
            default: 'If character count is between <strong>{{minLength}}</strong> and <strong>{{maxLength}}</strong>',
            [BlueprintCollectionOperator.every]:
              'If <strong>every</strong> field of <strong>{{fieldName}}</strong> have a character count between <strong>{{minLength}}</strong> and <strong>{{maxLength}}</strong>',
            [BlueprintCollectionOperator.some]:
              'If <strong>some</strong> fields of <strong>{{fieldName}}</strong> have a character count between <strong>{{minLength}}</strong> and <strong>{{maxLength}}</strong>',
            [BlueprintCollectionOperator.none]:
              'If <strong>no</strong> fields of <strong>{{fieldName}}</strong> have a character count between <strong>{{minLength}}</strong> and <strong>{{maxLength}}</strong>',
          },
          [ConditionBlueprintType.countEqual]: {
            prefix: {
              default:
                'If the count of answers with value <strong>{{controlValue}}</strong> to the field <strong>{{targetNodeId}}</strong> is ',
              [BlueprintCollectionOperator.every]:
                'If the count of <strong>every</strong> answer with value <strong>{{controlValue}}</strong> to the field <strong>{{targetNodeId}}</strong> is ',
              [BlueprintCollectionOperator.some]:
                'If the count of <strong>some</strong> answers with value <strong>{{controlValue}}</strong> to the field <strong>{{targetNodeId}}</strong> is ',
              [BlueprintCollectionOperator.none]:
                'If the count of <strong>no</strong> answers with value <strong>{{controlValue}}</strong> to the field <strong>{{targetNodeId}}</strong> is ',
            },
            suffix: {
              [NumberComparisonConditionOperator.greaterThan]: 'greater than <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.lessThan]: 'less than <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.lessThanOrEqual]: 'less than or equal to <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.greaterThanOrEqual]:
                'greater than or equal to <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.equal]: 'equal to  <strong>{{value}}</strong>',
            },
          },
          [ConditionBlueprintType.dateComparison]: {
            prefix: {
              default:
                'If the difference between the <strong>{{endDateFieldName}}</strong> (end date) and the <strong>{{startDateFieldName}}</strong> (start date) is ',
              [BlueprintCollectionOperator.every]:
                'If <strong>every</strong> difference between the <strong>{{endDateFieldName}}</strong> (end date) and the <strong>{{startDateFieldName}}</strong> (start date) is ',
              [BlueprintCollectionOperator.some]:
                'If <strong>some</strong> difference between the <strong>{{endDateFieldName}}</strong> (end date) and the <strong>{{startDateFieldName}}</strong> (start date) is ',
              [BlueprintCollectionOperator.none]:
                'If <strong>no</strong difference between the <strong>{{endDateFieldName}}</strong> (end date) and the <strong>{{startDateFieldName}}</strong> (start date) is ',
            },
            suffix: {
              [NumberComparisonConditionOperator.equal]:
                '<strong>equal</strong> to <strong>{{value}} {{dateUnit}}</strong>',
              [NumberComparisonConditionOperator.lessThan]: '<strong>less than {{value}} {{dateUnit}}</strong>',
              [NumberComparisonConditionOperator.lessThanOrEqual]:
                '<strong>less than or equal</strong> to <strong>{{value}} {{dateUnit}}</strong>',
              [NumberComparisonConditionOperator.greaterThan]: '<strong>greater than {{value}} {{dateUnit}}</strong>',
              [NumberComparisonConditionOperator.greaterThanOrEqual]:
                '<strong>greater than or equal</strong> to <strong>{{value}} {{dateUnit}}</strong>',
            },
          },
          [ConditionBlueprintType.emptiness]: {
            default: {
              true: 'If field <strong>{{fieldName}}</strong> is <strong>empty</strong>',
              false: 'If field <strong>{{fieldName}}</strong> is <strong>not empty</strong>',
            },
            [BlueprintCollectionOperator.every]: {
              true: 'If <strong>every</strong> field of <strong>{{fieldName}}</strong> is <strong>empty</strong>',
              false: 'If <strong>every</strong> field of <strong>{{fieldName}}</strong> is <strong>not empty</strong>',
            },
            [BlueprintCollectionOperator.some]: {
              true: 'If <strong>some</strong> fields of <strong>{{fieldName}}</strong> is <strong>empty</strong>',
              false: 'If <strong>some</strong> fields of <strong>{{fieldName}}</strong> is <strong>not empty</strong>',
            },
            [BlueprintCollectionOperator.none]: {
              true: 'If <strong>no</strong> fields of <strong>{{fieldName}}</strong> is <strong>empty</strong>',
              false: 'If <strong>no</strong> fields of <strong>{{fieldName}}</strong> is <strong>not empty</strong>',
            },
          },
          [ConditionBlueprintType.equality]: {
            default: {
              true: 'If field <strong>{{fieldName}}</strong> value is equal to <strong>{{value}}</strong>',
              false: "If field <strong>{{fieldName}}</strong> value isn't equal to <strong>{{value}}</strong>",
            },
            [BlueprintCollectionOperator.every]: {
              true: 'If <strong>every</strong> field of <strong>{{fieldName}}</strong> value is equal to <strong>{{value}}</strong>',
              false:
                "If <strong>every</strong> field of <strong>{{fieldName}}</strong> value isn't equal to <strong>{{value}}</strong>",
            },
            [BlueprintCollectionOperator.some]: {
              true: 'If <strong>some</strong> fields of <strong>{{fieldName}}</strong> value is equal to <strong>{{value}}</strong>',
              false:
                "If <strong>some</strong> fields of <strong>{{fieldName}}</strong> value isn't equal to <strong>{{value}}</strong>",
            },
            [BlueprintCollectionOperator.none]: {
              true: 'If <strong>no</strong> fields of <strong>{{fieldName}}</strong> value is equal to <strong>{{value}}</strong>',
              false:
                "If <strong>no</strong> fields of <strong>{{fieldName}}</strong> value isn't equal to <strong>{{value}}</strong>",
            },
          },
          [ConditionBlueprintType.futureDate]: {
            prefix: {
              default: 'If the date referred to in <strong>{{fieldName}}</strong> ',
              [BlueprintCollectionOperator.every]:
                'If <strong>every</strong> date referred to in <strong>{{fieldName}}</strong> ',
              [BlueprintCollectionOperator.some]:
                'If <strong>some</strong> dates referred to in <strong>{{fieldName}}</strong> ',
              [BlueprintCollectionOperator.none]:
                'If <strong>no</strong> dates referred to in <strong>{{fieldName}}</strong> ',
            },
            suffix: {
              [NumberComparisonConditionOperator.greaterThan]:
                'is more than <strong>{{value}} {{dateUnit}}</strong> in the future',
              [NumberComparisonConditionOperator.lessThan]:
                'is less than <strong>{{value}} {{dateUnit}}</strong> in the future',
              [NumberComparisonConditionOperator.lessThanOrEqual]:
                'is less than or exactly <strong>{{value}} {{dateUnit}}</strong> in the future',
              [NumberComparisonConditionOperator.greaterThanOrEqual]:
                'is more than or exactly <strong>{{value}} {{dateUnit}}</strong> in the future',
            },
          },
          [ConditionBlueprintType.lastIncidentDate]: {
            prefix: {
              default: 'If the date of the last incident referred to in <strong>{{fieldName}}</strong> ',
              [BlueprintCollectionOperator.every]:
                'If <strong>every</strong> date of the last incident referred to in <strong>{{fieldName}}</strong> ',
              [BlueprintCollectionOperator.some]:
                'If <strong>some</strong> dates of the last incident referred to in <strong>{{fieldName}}</strong> ',
              [BlueprintCollectionOperator.none]:
                'If <strong>no</strong> dates of the last incident referred to in <strong>{{fieldName}}</strong> ',
            },
            suffix: {
              [NumberComparisonConditionOperator.greaterThan]:
                'was more than <strong>{{value}} {{dateUnit}}</strong> ago',
              [NumberComparisonConditionOperator.lessThan]: 'was less than <strong>{{value}} {{dateUnit}}</strong> ago',
              [NumberComparisonConditionOperator.lessThanOrEqual]:
                'was less than or exactly <strong>{{value}} {{dateUnit}}</strong> ago',
              [NumberComparisonConditionOperator.greaterThanOrEqual]:
                'was more than or exactly <strong>{{value}} {{dateUnit}}</strong> ago',
            },
          },
          [ConditionBlueprintType.matches]: {
            default: {
              [MatchesConditionPropertyQuantifier.any]:
                'If field <strong>{{fieldName}}</strong> values contain one or more of: <strong>{{formattedValues}}</strong>',
              [MatchesConditionPropertyQuantifier.none]:
                'If field <strong>{{fieldName}}</strong> values do not contain any of: <strong>{{formattedValues}}</strong>',
            },
            [BlueprintCollectionOperator.every]: {
              [MatchesConditionPropertyQuantifier.any]:
                'If <strong>every</strong> field of <strong>{{fieldName}}</strong> values contain one or more of: <strong>{{formattedValues}}</strong>',
              [MatchesConditionPropertyQuantifier.none]:
                'If <strong>every</strong> field of <strong>{{fieldName}}</strong> values do not contain any of: <strong>{{formattedValues}}</strong>',
            },
            [BlueprintCollectionOperator.some]: {
              [MatchesConditionPropertyQuantifier.any]:
                'If <strong>some</strong> fields of <strong>{{fieldName}}</strong> values contain one or more of: <strong>{{formattedValues}}</strong>',
              [MatchesConditionPropertyQuantifier.none]:
                'If <strong>some</strong> fields of <strong>{{fieldName}}</strong> values do not contain any of: <strong>{{formattedValues}}</strong>',
            },
            [BlueprintCollectionOperator.none]: {
              [MatchesConditionPropertyQuantifier.any]:
                'If <strong>no</strong> fields of <strong>{{fieldName}}</strong> values contain one or more of: <strong>{{formattedValues}}</strong>',
              [MatchesConditionPropertyQuantifier.none]:
                'If <strong>no</strong> fields of <strong>{{fieldName}}</strong> values do not contain any of: <strong>{{formattedValues}}</strong>',
            },
          },
          [ConditionBlueprintType.matchesRegex]: {
            default: 'If the value of <strong>{{fieldName}}</strong> matches regex <strong>{{regex}}</strong>',
            [BlueprintCollectionOperator.every]:
              'If <strong>every</strong> field of <strong>{{fieldName}}</strong> matches regex <strong>{{regex}}</strong>',
            [BlueprintCollectionOperator.some]:
              'If <strong>some</strong> fields of <strong>{{fieldName}}</strong> match regex <strong>{{regex}}</strong>',
            [BlueprintCollectionOperator.none]:
              'If <strong>no</strong> fields of <strong>{{fieldName}}</strong> match regex <strong>{{regex}}</strong>',
          },
          [ConditionBlueprintType.mathOperator]: {
            prefix: {
              default: {
                [MathConditionOperator.sum]: 'If the sum of values in fields <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.multiply]: 'If the product of values in fields <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.subtract]:
                  'If the difference of values in fields <strong>{{fieldNames}}</strong> ',
              },
              [BlueprintCollectionOperator.every]: {
                [MathConditionOperator.sum]:
                  'If  <strong>every</strong> sum of values in fields <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.multiply]:
                  'If  <strong>every</strong> product of values in fields <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.subtract]:
                  'If  <strong>every</strong> difference of values in fields <strong>{{fieldNames}}</strong> ',
              },
              [BlueprintCollectionOperator.some]: {
                [MathConditionOperator.sum]:
                  'If <strong>some</strong> sum of values in fields <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.multiply]:
                  'If <strong>some</strong> product of values in fields <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.subtract]:
                  'If  <strong>some</strong> difference of values in fields <strong>{{fieldNames}}</strong> ',
              },
              [BlueprintCollectionOperator.none]: {
                [MathConditionOperator.sum]:
                  'If <strong>no</strong> sum of values in fields <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.multiply]:
                  'If <strong>no</strong> product of values in fields <strong>{{fieldNames}}</strong> ',
                [MathConditionOperator.subtract]:
                  'If <strong>no</strong> difference of values in fields <strong>{{fieldNames}}</strong> ',
              },
            },
            suffix: {
              [NumberComparisonConditionOperator.greaterThan]: 'is greater than <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.lessThan]: 'is less than <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.lessThanOrEqual]:
                'is less than or equal to <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.greaterThanOrEqual]:
                'is greater than or equal to <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.equal]: 'is equal to <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.multipleOf]: 'is multiple of <strong>{{value}}</strong>',
            },
          },
          [ConditionBlueprintType.numberComparison]: {
            default:
              'If numerical field <strong>{{fieldName}}</strong> value is <strong>{{operator}}</strong> {{conjunction}} <strong>{{value}}</strong>',
            [BlueprintCollectionOperator.every]:
              'If <strong>every</strong> numerical field <strong>{{fieldName}}</strong> value is <strong>{{operator}}</strong> {{conjunction}} <strong>{{value}}</strong>',
            [BlueprintCollectionOperator.some]:
              'If <strong>some</strong> numerical field <strong>{{fieldName}}</strong> value is <strong>{{operator}}</strong> {{conjunction}} <strong>{{value}}</strong>',
            [BlueprintCollectionOperator.none]:
              'If <strong>no</strong> numerical field <strong>{{fieldName}}</strong> value is <strong>{{operator}}</strong> {{conjunction}} <strong>{{value}}</strong>',
          },
          [ConditionBlueprintType.percentOf]: {
            prefix: {
              default:
                'If <strong>{{percent}}%</strong> of numerical field value <strong>{{targetNodeId}}</strong> is ',
            },
            suffix: {
              [NumberComparisonConditionOperator.greaterThan]: 'greater than <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.lessThan]: 'less than <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.lessThanOrEqual]: 'less than or equal to <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.greaterThanOrEqual]:
                'greater than or equal to <strong>{{value}}</strong>',
              [NumberComparisonConditionOperator.equal]: 'equal to  <strong>{{value}}</strong>',
            },
          },
          [ConditionBlueprintType.reflexive]: {
            default: 'If field <strong>{{fieldName}}</strong> value is <strong>{{value}}</strong>',
            [BlueprintCollectionOperator.every]:
              'If <strong>every</strong> field of <strong>{{fieldName}}</strong> value is <strong>{{value}}</strong>',
            [BlueprintCollectionOperator.some]:
              'If <strong>some</strong> fields of <strong>{{fieldName}}</strong> value is <strong>{{value}}</strong>',
            [BlueprintCollectionOperator.none]:
              'If <strong>no</strong> fields of <strong>{{fieldName}}</strong> value is <strong>{{value}}</strong>',
          },
        },
      },
      options: {
        collectionOperator: {
          [BlueprintCollectionOperator.every]: 'Every',
          [BlueprintCollectionOperator.some]: 'Some',
          [BlueprintCollectionOperator.none]: 'None',
          [BlueprintCollectionOperator.thisItem]: 'Containing collection',
        },
        conditionProperty: {
          [ConditionBlueprintType.ageRange]: 'Age is in range',
          [ConditionBlueprintType.bmiRange]: 'BMI value is in range',
          [ConditionBlueprintType.characterCountInBetween]: 'Character count is in range',
          [ConditionBlueprintType.countEqual]: 'Answer count is',
          [ConditionBlueprintType.dateComparison]: 'Date comparison',
          [ConditionBlueprintType.emptiness]: "Field value is/isn't empty",
          [ConditionBlueprintType.engineCondition]: 'Engine Condition (for blueprint effects only)',
          [ConditionBlueprintType.equality]: "Field value is/isn't equal to",
          [ConditionBlueprintType.futureDate]: 'Future date limit',
          [ConditionBlueprintType.instancesCount]: 'Instances count (for blueprint effects only)',
          [ConditionBlueprintType.jointProductAgeRange]: 'Joint product age range (for blueprint effects only)',
          [ConditionBlueprintType.lastIncidentDate]: 'Last incident date is',
          [ConditionBlueprintType.matches]: "Field value is/isn't matching",
          [ConditionBlueprintType.matchesRegex]: 'Matches regular expression',
          [ConditionBlueprintType.mathOperator]: 'Calculated value is',
          [ConditionBlueprintType.numberComparison]: 'Numerical field value is',
          [ConditionBlueprintType.percentOf]: 'Numerical field value is a percent of',
          [ConditionBlueprintType.reflexive]: 'Field value is yes/no',
        },
        yesNo: {
          [YesNoValue.no]: 'No',
          [YesNoValue.yes]: 'Yes',
        },
        emptiness: {
          empty: 'Is empty',
          notEmpty: 'Is not empty',
        },
        equality: {
          equal: 'Is equal',
          notEqual: 'Is not equal',
        },
        numberComparison: {
          [NumberComparisonConditionOperator.equal]: 'Equal',
          [NumberComparisonConditionOperator.greaterThan]: 'Greater than',
          [NumberComparisonConditionOperator.greaterThanOrEqual]: 'Greater than or equal',
          [NumberComparisonConditionOperator.lessThan]: 'Less than',
          [NumberComparisonConditionOperator.lessThanOrEqual]: 'Less than or equal',
          [NumberComparisonConditionOperator.multipleOf]: 'Multiple of',
        },
        measurementSystem: {
          [MeasurementSystem.imperial]: 'Imperial',
          [MeasurementSystem.metric]: 'Metric',
        },
        matches: {
          [MatchesConditionPropertyQuantifier.any]: 'Any',
          [MatchesConditionPropertyQuantifier.none]: 'None',
        },
        mathConditionOperator: {
          [MathConditionOperator.multiply]: 'Multiply',
          [MathConditionOperator.subtract]: 'Subtract',
          [MathConditionOperator.sum]: 'Sum',
        },
        dateUnit: {
          [DateUnit.day]: 'Days',
          [DateUnit.month]: 'Months',
          [DateUnit.year]: 'Years',
        },
      },
    },
    auditLogs: {
      logEntity: {
        plural: 'audit logs',
        singular: 'audit log',
      },
      resourceName: 'Resource Name',
      resourceId: 'Resource Id',
      actionType: 'Action Type',
      userName: 'User Name',
      userRole: 'User Role',
      timestamp: 'Timestamp',
      details: 'Details',
      modalTitle: 'Action Details',
      modalLabel: 'Updated Properties',
      options: {
        any: 'Any',
        resourceName: {
          [AdminToolResourceName.product]: 'Product',
          [AdminToolResourceName.salesDecisionRule]: 'Sales decision rule',
          [AdminToolResourceName.productPricing]: 'Product Pricing',
          [AdminToolResourceName.firm]: 'Firm',
          [AdminToolResourceName.theme]: 'Theme',
          [AdminToolResourceName.settings]: 'Settings',
          [AdminToolResourceName.questionnaire]: 'Questionnaire',
          [AdminToolResourceName.submittedApplicationStatusReset]: 'Application support - Submission reset',
        },
        actionType: {
          [AdminToolActionType.create]: 'Create',
          [AdminToolActionType.read]: 'Read',
          [AdminToolActionType.update]: 'Update',
          [AdminToolActionType.delete]: 'Delete',
          [AdminToolActionType.import]: 'Import',
        },
      },
    },
    questionnaireBuilder: {
      uploadButton: 'Upload a Carrier Questionnaire',
      uploadModal: 'Select a Carrier Questionnaire (PDF)',
    },
    thirdPartyIntegrations: {
      status: {
        [ThirdPartyIntegrationStatus.active]: 'Active',
        [ThirdPartyIntegrationStatus.inactive]: 'Inactive',
      },
      buttonLabels: {
        manage: 'Manage',
      },
    },
  },
  canadianProvinces: {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NT: 'Northwest Territories',
    NS: 'Nova Scotia',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon Territory',
  },
  usStates: {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UM: 'United States Minor Outlying Islands',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },
  dataSizeLabels: {
    B: 'B',
    KB: 'KB',
    MB: 'MB',
    GB: 'GB',
    TB: 'TB',
  },
  outcomes: {
    [OutcomeCode.referred]: {
      label: 'Referred to underwriting',
      tooltip: '',
    },
    [OutcomeCode.denied]: {
      label: 'Denied',
      tooltip: '',
    },
    [OutcomeCode.blocked]: {
      label: 'Blocked',
      tooltip: '',
    },
    [OutcomeCode.blockedAtQuoter]: {
      label: 'Blocked at quoter',
      tooltip: '',
    },
    [OutcomeCode.accepted]: {
      label: 'Accepted',
      tooltip: '',
    },
    [OutcomeCode.conditional]: {
      label: 'Conditionally accepted',
      tooltip: '',
    },
    [OutcomeCode.notApplicable]: {
      label: 'Not applicable',
      tooltip: '',
    },
    [OutcomeCode.unknown]: {
      label: 'Unknown',
      tooltip: '',
    },
    underReview: {
      // this outcome is only used by Beneva
      label: 'Application under review',
      tooltip: 'Your application is under review by the Insurer.',
    },
    conditionalApproval: {
      // this outcome is only used by Beneva
      label: 'Conditional Approval',
      tooltip:
        'Your application has received conditional and provisional approval. This conditional approval can be revoked at any time until the application has been completed and signed by all policyholders and proposed insureds. Your insurance application has therefore not been accepted by the Insurer and your insurance has not taken effect.',
    },
  },
  eSignature: {
    ceremonyStatus: eSignatureStatus.ceremonyStatus,
    signerStatus: eSignatureStatus.signerStatus,
  },
  skipLinks: {
    header: 'Skip header',
    navigation: 'Skip navigation',
  },
  notificationCentre: {
    title: 'Notification Centre',
    welcomeMessage: 'Welcome, {{name}}',
    unreadNotificationsBadge: {
      zero: 'No new notification',
      singular: '1 new notification',
      plurial: '{{value}} new notifications',
    },
    markAllAsRead: 'Mark all as read',
    event: {
      [NotificationEventName.NewFeatureLaunch]: {
        title: 'New Feature',
        mainContent: 'To discover the new feature, click on the expand arrow on the right.',
        hiddenContent: '...',
      },
      [NotificationEventName.ApplicationSubmitted]: {
        title: 'Application Submitted Successfully',
        mainContent:
          '<span> All signers have signed for application <a href="{{url}}">{{applicationRefNumber}}</a>. It has been submitted successfully! 🎉<span/>',
        hiddenContent: '',
      },
      [NotificationEventName.OneSpanEmailBounce]: {
        title: 'Email Bounce Identified',
        mainContent:
          'We have identified that one of the signers in application {{applicationRefNumber}} had an email bounce. Please review the signature ceremony.',
        hiddenContent: '<br>Signer Name: {{signerName}} <br> Email Address: {{email}} <br> Roles: {{roles}}',
      },
      [NotificationEventName.OneSpanCreatePackageError]: {
        title: 'OneSpan Created Package Error',
        mainContent:
          'We have identified that the signature ceremony failed to start in application {{applicationRefNumber}}. Please cancel the existing signature ceremony and try again.',
        hiddenContent: '',
      },
    },
    helpPopover: {
      title: 'Welcome to the Notification Centre!',
      main:
        '<p>The Notification Centre is your personal assistant who will inform you about your applications that are passing through the platform. It will also help you manage and ensure that your applications are submitted successfully to {{companyName}}.</p>' +
        '<p>Here are some guidelines about the type of notifications that we currently support: </p>',
      informational: {
        content:
          '<p><i><u>Informational:</u> This type of notification informs you about the progress of an application. You can enter the application by clicking on the application number.</i></p>',
        alt: 'Example of an informative notification',
      },
      actionable: {
        content:
          '<p> <i><u>Actionable:</u> This type of notification informs you of any actions you must attend to with an application. You can enter the application by clicking anywhere on the notification.</i></p>',
        alt: 'Example of an actionable notification',
      },
    },
    markAllAsReadPopover: {
      content: 'Are you sure you want to mark all notifications as read?',
      confirmButton: 'Yes',
    },
    markAsUnread: 'Mark as unread',
    feedbackMessage: 'Let us know what you think of our new Notification Centre!',
    helpMessage: 'Learn more about the Notification Centre',
    errors: {
      updateNotificationError: 'Failed update the notification',
      updateNotificationsError: 'Failed to update all the notifications',
    },
    loadMoreNotifications: 'Load more notifications',
    allLatestNotificationsLoaded: 'No more notifications from the last {{maxDays}} days 🎊',
  },
};

export default translation;
